import React, { useEffect, useState } from 'react';
import { Link, NavLink } from 'react-router-dom';
import {
  clearAccessToken,
  clearUserFromLocalstorage,
} from '../../../services/localstorage';
import UIButton from '../../ui/UIButton/UIButton';
import { TbChevronDown } from 'react-icons/tb';
import { Avatar, Button, Col, Layout, Row } from 'antd';
import './Header.scss';
import { useAuth } from '../../../context/authContext';

const UserAvatarName = ({ user, organisation }) => {
  return (
    <div className="user-avatar-name">
      <Avatar
        style={{
          backgroundColor: '#87d068',
        }}
      >
        {user?.name[0]}
      </Avatar>
      <div className="name-block">
        <TbChevronDown />
        {/* <h4>{user?.name}</h4> */}
        {/* <p>{organisation?.title || 'NodeCompose'}</p> */}
      </div>
    </div>
  );
};

export default function Header() {
  const { user } = useAuth();

  const handleLogout = () => {
    clearAccessToken();
    clearUserFromLocalstorage();
    window.location.href = '/login';
  };

  const activeClassFunc = ({ isActive, isPending }) =>
    isPending ? 'pending' : isActive ? 'active' : '';

  return (
    <div className="main-header">
      <div className="logo-wrapper">
        <img src="/nodecompose-logo-light.png" alt="logo" />
      </div>
      <div className="main-navigation">
        <div className="main-navigation__left">
          <ul>
            <li>
              <NavLink to="/" className={activeClassFunc}>
                Dashboard
              </NavLink>
            </li>
            <li>
              <NavLink to="/projects" className={activeClassFunc}>
                Projects
              </NavLink>
            </li>
            <li>
              <NavLink to="/settings" className={activeClassFunc}>
                Settings
              </NavLink>
            </li>
            {/* <li>
              <Link to="/settings">Settings</Link>
            </li> */}
          </ul>
        </div>
        <div className="main-navigation__right">
          <ul className="navbar-nav ms-auto mb-2 mb-lg-0">
            <li className="nav-item dropdown">
              <a className="nav-link dropdown-toggle">
                <UserAvatarName user={user} />
                {/* <TbChevronDown /> */}
              </a>
              <ul className="dropdown-menu">
                {/* <li>
                  <Link to="/settings" className="dropdown-item">
                    Switch Workspace
                  </Link>
                </li> */}
                <li>
                  <Link to="/settings" className="dropdown-item">
                    Settings
                  </Link>
                </li>
                <li>
                  <Button
                    type="text"
                    onClick={handleLogout}
                    className="dropdown-item"
                  >
                    Logout
                  </Button>
                </li>
                {/* <li><a className="dropdown-item" href="#">Another action</a></li> */}
              </ul>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
}
