import React from 'react'
import ProjectLayout from './ProjectLayout'
import TemplateList from '../../containers/TemplatesList/TemplatesList'

export default function TemplatesHome() {
  return (
    <ProjectLayout title={"Templates"}  heading={"Browse our collection of templates to get started."}>
      <TemplateList />
    </ProjectLayout>
  )
}
