import React from 'react';
import ProjectSection from '../../components/sections/ProjectSection/ProjectSection';
import ContentSection from '../../components/sections/ContentSection/ContentSection';
import { Button, Card, Checkbox, Col, Form, Modal, Radio, Row, Tag } from 'antd';
import { TbBrandTelegram, TbCheck, TbCheckbox, TbSend } from 'react-icons/tb';
import ProjectEmptyState from '../../components/projects/ProjectEmptyState/ProjectEmptyState';
import UIButton from '../../components/ui/UIButton/UIButton';
import { useProject } from '../../context/project';
import { checkIfModuleIsEnabled, selectProject } from '../../context/project/selectors';
import { useMutation } from 'react-query';
import { enableModuleApi } from '../../services/api.service';
import { fetchProjectEffect } from '../../context/project/effects';
import ProjectPaymentList from './ProjectPaymentList';
import { updateDeployingStatus } from '../../context/project/actions';

export default function ProjectPayments() {
  const [paymentModule, setPaymentModule] = React.useState(false);
  const state = useProject();
  const project = selectProject(state);
  const isPaymentEnabled = checkIfModuleIsEnabled(state, 'payments');

  const { dispatch } = useProject();
  const mutation = useMutation((values) => enableModuleApi(project._id, 'payments', values), {
    onSuccess: () => {
      fetchProjectEffect(dispatch, project._id);
    }
  });

  const handleSubmit = (values) => {
    setPaymentModule(false);
    dispatch(updateDeployingStatus(true));
    setTimeout(() => {
      mutation.mutate(values);
      dispatch(updateDeployingStatus(false));
    }, 2000);
    setPaymentModule(false);
  }

  return (
    <ProjectSection
      title={'Payments'}
      actions={isPaymentEnabled ? (
        <Tag className='px-3 py-1' color='green'>Enabled</Tag>
      ) : (
        <Button
          type="primary"
          icon={<TbCheckbox />}
          className="d-inline-flex align-items-center"
          onClick={() => setPaymentModule(true)}
        >
          Enable Payments
        </Button>
      )
      }
    >
      <ContentSection>
        { isPaymentEnabled && <ProjectPaymentList />}
        { !isPaymentEnabled && (
          <ProjectEmptyState
            image={'/images/online-payment.png'}
            title={
              'Activate the Payment and collect one-time or subscription payments'
            }
            description={
              'Offer a seamless payment experience to your users. Enhance convenience, increase conversions, and build a robust financial foundation for your digital ecosystem.'
            }
            btnText={'Enable Payments'}
            onClick={() => setPaymentModule(true)}
          />
        )}
      </ContentSection>
      <Modal open={paymentModule} footer={false} title="Enable Payment Module" onCancel={() => setPaymentModule(false)} destroyOnClose>
        <p>This would enable the payment related apis for your project. </p>
        <Form layout='vertical' onFinish={handleSubmit}>
          {/* <Form.Item name="enablePayments" valuePropName="checked">
            <Checkbox>Please accept the terms and conditions to enable payments.</Checkbox>
          </Form.Item> */}
          <div className="p-3 bg-light border rounded">
            <Form.Item name="enablePayments" valuePropName="checked" label="Select the type of payments you want to accept" className='mb-0'>
              <Radio.Group>
                <Radio value={'one-time'}>Accept one-time payments</Radio>
                <Radio value={'subscription'}>Accept subscription payments</Radio>
              </Radio.Group>
            </Form.Item>
          </div>
          <p className='my-2 text-muted'>Payments are powered by Stripe.</p>
          <div className="mt-4"></div>
          <UIButton
            title={'Enable Payments'}
            theme="primary"
            icon={<TbCheck />}
            type="submit"
          />
        </Form>
      </Modal>
    </ProjectSection>
  );
}
