import React from 'react';
import './AuthLayout.scss';

export default function AuthLayout({ children }) {
  return (
    <div className="main-app-wrapper">
      <div className="main-page-wrapper">
        <div className="auth-section py-5">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-md-6 mx-auto text-center mb-5">
                <div className="page-content-logo">
                  <img src="/logo-nodecompose-light.svg" />
                </div>
                <div className="block p-5 mt-3 text-start">{children}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
