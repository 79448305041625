import { getAccessToken, getUserFromLocalstorage, saveUserToLocalstorage } from '../services/localstorage';
import { createContext, useState, useContext, useEffect } from 'react';

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(undefined);
  const [userLoaded, setUserLoaded] = useState(false);

  useEffect(() => {
    const token = getAccessToken();
    if(token){
      const user = getUserFromLocalstorage();
      setUser(user);
      // window.location.href = '/';
    } else {
      setUser(null);
      // window.location.href = '/login';
    }
    setUserLoaded(true);
  }, []);

  const updateUser = (user) => {
    setUser(user);
    saveUserToLocalstorage(user);
  };

  return (
    <AuthContext.Provider value={{ user, setUser, updateUser, userLoaded }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext);
