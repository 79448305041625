import React, { useEffect } from 'react';
import { Button, Form, Input, Table, Space, Popconfirm, Avatar } from 'antd';
import SettingsPage from './SettingPage';
import { getOrganizationsApi } from '../../services/api.service';

const UsersTable = () => {
    const [organizations, setOrganizations] = React.useState([]);

    useEffect(() => {
        getOrganizationsApi().then(({ data }) => {
            console.log('data', data);
            setOrganizations(data);
        });
    }, []);

    return (
        <Table
            dataSource={organizations}
            columns={[
                {
                    title: "Name",
                    dataIndex: "members",
                    key: "members",
                    render: (_, { members }) =>
                        <>
                            {members.map((member, index) => {
                                return (
                                    <div key={index}>
                                        {member.name}
                                    </div>
                                )
                            })}
                        </>
                },
                {
                    title: "Email",
                    dataIndex: "members",
                    key: "members",
                    render: (_, { members }) =>
                        <>
                            {members.map((member, index) => {
                                return (
                                    <div key={index}>
                                        {member.email}
                                    </div>
                                )
                            })}
                        </>
                },
                {
                    title: "Action",
                    key: "action",
                    render: (_, record) => (
                        <Space>
                            <Button
                                size="medium"
                                type="text"

                            >
                                Edit
                            </Button>
                            <Popconfirm
                                title="Are you sure to delete this email?"
                                placement="leftBottom"
                            >
                                <Button
                                    size="medium"
                                    type="text"
                                    key={"actions"}
                                >
                                    Delete
                                </Button>
                            </Popconfirm>
                        </Space>
                    )
                }

            ]}
            pagination={false}
        />
    );
};

export default function OrganizationSettings() {
    return (
        <SettingsPage title={'Manage Your Account'}>
            <UsersTable />
        </SettingsPage>
    );
}

