'use client';
import { createContext, useContext, useState } from 'react';

export const ModalContext = createContext();

export const MODALS = {
  CREATE_PROJECT_MODAL: 'showCreateProjectModal',
  PROJECT_MODAL: 'showProjectModal',
  DEPLOY_MODAL: 'showDeployModal',
  ENABLE_AUTHENTICATION_MODAL: 'showEnableAuthenticationModal',
  SELECT_NEW_PROJECT_TYPE: 'showSelectNewProjectType',
  UPDATE_SCHEMA_MODAL: 'showUpdateSchemaModal',
};

const initialState = {
  showCreateProjectModal: false,
  showProjectModal: false,
  showDeployModal: false,
  showEnableAuthenticationModal: false,
  showSelectNewProjectType: false,
  showUpdateSchemaModal: false,
}

export const ModalProvider = ({ children }) => {
  const [modals, setModals] = useState({
    ...initialState,
    data: {
      selectedTemplate: null,
      selectedProject: null,
    },
  });

  const showModal = (modal, data) => {
    const state = {
      ...initialState,
      [modal]: true,
      data: {
        ...modals.data,
        ...data,
      },
    };
    setModals(state);
  };

  const hideModal = (modal) => {
    const state = {
      ...modals,
      [modal]: false,
      data: {
        selectedTemplate: null,
        selectedProject: null,
      }
    };
    console.log('state', state);
    setModals(state);
  };

  return (
    <ModalContext.Provider
      value={{
        modals,
        showModal,
        hideModal,
      }}
    >
      {children}
    </ModalContext.Provider>
  );
};

export const useModals = () => useContext(ModalContext);
