import React from 'react';
import { PROJECT_MODALS } from '../context/projectContext';
import { Form, Input, Modal, notification } from 'antd';
import UIButton from '../components/ui/UIButton/UIButton';
import { TbCheck } from 'react-icons/tb';
import { createTableApi } from '../services/api.service';
import { useProject } from '../context/project';
import { useMutation, useQueryClient } from 'react-query';
import { ACTION_TYPES, hideModalAction } from '../context/project/actions';
import { capitalizeCollection } from '../utils';
import { selectProject } from '../context/project/selectors';

export const CreateCollection = ({ project }) => {

  const state = useProject();
  const { dispatch } = state;
  const queryClient = useQueryClient();
  const mutation = useMutation(createTableApi, {
    onSuccess: (data) => {
      notification.success({
        message: 'Success',
        description: 'Collection created successfully'
      });
      queryClient.invalidateQueries('tables');
    }
  });

  const handleSubmit = (values) => {
    const projectId = project._id;
    dispatch(hideModalAction(PROJECT_MODALS.CREATE_COLLECTION_MODAL));
    const capitalizedTitle = capitalizeCollection(values.title);
    mutation.mutate({
      projectId: projectId,
      body: {
        title: values.title,
        modelName: capitalizedTitle,
        schemaString: '# Add your schema here\n' + capitalizedTitle + ':',
        project: projectId,
      }
    });
  };

  return (
    <Form
      onFinish={handleSubmit}
      initialValues={{
        enableMobileLogin: false,
      }}
      layout="vertical"
    >
      <Form.Item label="Collection Name" name={'title'}>
        <Input size="large" />
      </Form.Item>
      <UIButton
        title={'Create Collection'}
        theme="primary"
        icon={<TbCheck />}
        type="submit"
      />
    </Form>
  );
};

export default function CreateCollectionModal() {
  const { project, modals, dispatch } = useProject();
  const { showCreateCollectionModal } = modals;

  const handleHideModal = () => {
    dispatch(hideModalAction(PROJECT_MODALS.CREATE_COLLECTION_MODAL));
  };

  return (
    <Modal
      title="Create Collection"
      onCancel={handleHideModal}
      open={showCreateCollectionModal}
      footer={false}
      destroyOnClose={true}
    >
      <CreateCollection project={project} />
    </Modal>
  );
}
