import { Alert, Form, Input, notification } from 'antd';
import React, { useEffect } from 'react';
import {
  Link,
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from 'react-router-dom';
import { useAuth } from '../../context/authContext';
import { loginApi } from '../../services/api.service';
import {
  saveUserToLocalstorage,
  setAccessToken,
} from '../../services/localstorage';

export default function Login() {
  const { user, setUser } = useAuth();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const reset = searchParams.get('reset');

  const handleSubmit = (values) => {
    loginApi(values)
      .then(({ data }) => {
        setAccessToken(data.token);
        setUser(data);
        saveUserToLocalstorage(data);
        window.location.href = '/';
      })
      .catch((err) => {
        notification.error({
          message: 'Invalid Credentials',
          description: err.message,
        });
      });
  };

  return (
    <div className="form-wrapper auth-form pt-5 p-3">
      <Form layout="vertical" onFinish={handleSubmit}>
        <h3>Login to your Account</h3>
        <hr />
        {reset && (
          <div className="mt-4 mb-3">
            <Alert
              description="Your password has been reset successfully."
              type="success"
              showIcon
            />
          </div>
        )}
        <Form.Item
          name={'email'}
          label="Email address"
          rules={[
            {
              required: true,
              message: 'Please input your email!',
            },
          ]}
        >
          <Input placeholder="tony@starkindustries.com" />
        </Form.Item>

        <Form.Item
          name={'password'}
          rules={[
            {
              required: true,
              message: 'Please input your password!',
            },
          ]}
          label={
            <>
              Password
              <Link
                className="text-decoration-none forget-password-link"
                to="/forgot-password"
              >
                Forgot Password?
              </Link>
            </>
          }
        >
          <Input placeholder="*******" type="password" />
        </Form.Item>

        <button type="submit">Sign In</button>

        <p className="mt-4 account-link">
          Don't have an account? <Link to="/signup">Signup</Link>
        </p>
      </Form>
    </div>
  );
}
