import React from 'react';
import ProjectSection from '../../components/sections/ProjectSection/ProjectSection';
import {
  Button,
  Col,
  Form,
  Input,
  Modal,
  Row,
  Space,
  Switch,
  Table,
  message,
} from 'antd';
import { useParams } from 'react-router-dom';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import {
  createSingleTableData,
  deleteSingleTableData,
  getSingleTable,
  getSingleTableData,
} from '../../services/api.service';
import { convertSchemaObjectToSchemaArray } from '../../utils';
import {
  TbCactus,
  TbEdit,
  TbFilter,
  TbPlus,
  TbSort09,
  TbSortAZ,
  TbSortAscending,
  TbTrash,
} from 'react-icons/tb';
import { MODALS, useModals } from '../../context/modalContext';
import ProjectCRMCreateForm from '../../components/projects/ProjectCRMCreateForm/ProjectCRMCreateForm';

export default function ProjectDataCRMCollection() {
  const params = useParams();
  const { showModal } = useModals();
  const project = params.project;
  const collection = params.collection;
  const [createModalActive, setCreateModalActive] = React.useState(false);
  const [editingRecord, setEditingRecord] = React.useState(null);
  const queryClient = useQueryClient();

  const deleteMutation = useMutation(({ project, collection, recordId }) => deleteSingleTableData(project, collection, recordId), {
    onSuccess: (data) => {
      queryClient.invalidateQueries(['tableData', project, collection]);
      message.open({
        type: 'success',
        content: 'Data updated successfully',
        duration: 5,
      });
    },
  });

  const {
    data: tableData = [],
    isLoading,
    isError,
  } = useQuery(
    ['tableData', project, collection],
    () => getSingleTableData(project, collection),
    {
      enabled: !!(project && collection),
    }
  );

  const { data: collectionData } = useQuery(
    ['tables', project, collection],
    () => getSingleTable(project, collection),
    {
      enabled: !!(project && collection),
    }
  );

  const schema = collectionData?.schemaJson || {};
  const schemaArray = convertSchemaObjectToSchemaArray(schema);

  const showEditModal = () => {
    showModal(MODALS.UPDATE_SCHEMA_MODAL, {
      selectedCollection: collection,
    });
  };

  const handleDeleteRecord = (record) => {
    deleteMutation.mutate({
      project,
      collection,
      recordId: record._id
    });
  }

  return (
    <ProjectSection
      title={'Database CMS'}
      subTitle={collectionData?.title}
      actions={
        <Space>
          {/* <Button
            className="d-flex align-items-center"
            type="text"
            icon={<TbSortAscending size={16}  />}
          >
            Sort
          </Button>
          <Button
            className="d-flex align-items-center"
            type="text"
            icon={<TbFilter size={16}  />}
          >
            Filter
          </Button> */}
          <Button
            className="d-flex align-items-center"
            type="text"
            icon={<TbPlus size={16} />}
            onClick={() => {
              setCreateModalActive(true);
            }}
          >
            Add Record
          </Button>
          <Button
            className="d-flex align-items-center"
            type="text"
            icon={<TbEdit size={16} />}
            onClick={showEditModal}
          >
            Edit Schema
          </Button>
        </Space>
      }
    >
      <Table dataSource={tableData} pagination={{
        position: ['bottomCenter'],
        pageSize: 50,
      }}>
        {schemaArray.map((item) => {
          return <Table.Column title={item.title} dataIndex={item.name} />;
        })}
        <Table.Column
          width={100}
          render={(_, record) => {
            return (
              <Space>
                <Button type="text" className='px-1' onClick={() => {
                  setEditingRecord(record);
                  setCreateModalActive(true);
                }}>
                  <TbEdit />
                </Button>
                <Button type="text" className='px-1' onClick={() => handleDeleteRecord(record)}>
                  <TbTrash />
                </Button>
              </Space>
            );
          }}
        />
      </Table>
      <Modal
        width={700}
        open={createModalActive}
        footer={false}
        title="Create a New Record"
        onCancel={() => {
          setCreateModalActive(false);
        }}
        destroyOnClose={true}
      >
        <ProjectCRMCreateForm
          project={project}
          collection={collection}
          schemaArray={schemaArray}
          onCreate={() => {
            setCreateModalActive(false);
          }}
          onClose={() => {
            setCreateModalActive(false);
          }}
          record={editingRecord}
        />
      </Modal>
    </ProjectSection>
  );
}
