import React from 'react';
import DashboardHeader from '../../components/sections/DashboardHeader/DashboardHeader';
import UIButton from '../../components/ui/UIButton/UIButton';
import { Space } from 'antd';
import { TbPlus } from 'react-icons/tb';
import PageContent from '../../components/sections/PageContent/PageContent';
import ProjectLayout from './ProjectLayout';
import ProjectList from '../../containers/ProjectList/ProjectList';
import { MODALS, useModals } from '../../context/modalContext';

export default function ProjectHome() {

  const { showModal } = useModals();

  const handleAddProjectClick = () => {
    console.log('Add project clicked');
    showModal(MODALS.CREATE_PROJECT_MODAL);
  };
  
  return (
    <ProjectLayout title={"Projects"} heading={"Welcome back, Samar. Here's what's happening with your projects."}>
      <ProjectList onAddClick={handleAddProjectClick} />
    </ProjectLayout>
  );
}
