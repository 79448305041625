import React, { useEffect } from 'react';
import AceEditor from 'react-ace';

import 'ace-builds/src-noconflict/mode-yaml';
import 'ace-builds/src-noconflict/theme-monokai';
import 'ace-builds/src-noconflict/ext-language_tools';

export default function SchemaEditor({ schema, onChange}) {

  const [localSchema, setLocalSchema] = React.useState('');

  useEffect(() => {
    setLocalSchema(schema);
  }, [schema]);

  const handleChange = (schema) => {
    setLocalSchema(schema);
    onChange(schema);
  } 

  return (
    <AceEditor
      width="100%"
      height="100%"
      mode="yaml"
      theme="monokai"
      value={localSchema}
      onChange={handleChange}
      name="editor-div"
      editorProps={{ $blockScrolling: true }}
      setOptions={{
        useWorker: false,
        tabSize: 2,
        fontSize: 13,
        enableBasicAutocompletion: true,
        enableLiveAutocompletion: false,
      }}
    />
  );
}
