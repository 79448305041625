import { produce } from 'immer';
import { ACTION_TYPES } from './actions';

export const initialState = {
  project: null,
  saved: null,
  deploying: false,
  schema: null,
  modals: {
    showEnableAuthenticationModal: false,
    showDeployModal: false,
    showCreateCollectionModal: false,
  },
  collections: [],
  activeCollection: null,
  updatedCollections: {
  }
};

const projectReducer = (state, action) => {
  switch (action.type) {
    case ACTION_TYPES.SET_PROJECT:
      return produce(state, (draft) => {
        draft.project = action.payload;
        draft.schema = action.payload.schema;
      });
    case ACTION_TYPES.SET_SAVED:
      return produce(state, (draft) => {
        draft.saved = action.payload;
      });
    case ACTION_TYPES.SET_SCHEMA:
      return produce(state, (draft) => {
        draft.schema = action.payload;
      });
    case ACTION_TYPES.SHOW_MODAL:
      return produce(state, (draft) => {
        draft.modals[action.payload] = true;
      });
    case ACTION_TYPES.HIDE_MODAL:
      return produce(state, (draft) => {
        draft.modals[action.payload] = false;
      });
    case ACTION_TYPES.SET_COLLECTIONS:
      return produce(state, (draft) => {
        draft.collections = action.payload;
      });
    case ACTION_TYPES.SET_ACTIVE_COLLECTION:
      return produce(state, (draft) => {
        draft.activeCollection = action.payload;
      });
    case ACTION_TYPES.REMOVE_UPDATED_COLLECTION:
      return produce(state, (draft) => {
        delete draft.updatedCollections[action.payload];
      });
    case ACTION_TYPES.UPDATE_COLLECTION:
      return produce(state, (draft) => {
        const { _id: collectionId, ...rest } = action.payload;
        draft.updatedCollections[collectionId] = {
          ...(draft.updatedCollections[collectionId] || {}),
          ...rest
        }
      });
    case ACTION_TYPES.UPDATE_DEPLOYING_STATUS:
      return produce(state, (draft) => {
        draft.deploying = action.payload;
      });
    default:
      return state;
  }
};

export default projectReducer;
