import React, { useEffect } from 'react';
import { Link, NavLink, useNavigate, useParams } from 'react-router-dom';
import './DatabaseHome.scss';
import { Alert, Button, Empty, Table, notification } from 'antd';
import { TbPlus } from 'react-icons/tb';
import ContentSection from '../../components/sections/ContentSection/ContentSection';
import SchemaEditor from '../SchemaEditor/SchemaEditor';
import { PROJECT_MODALS } from '../../context/projectContext';
import { getTables } from '../../services/api.service';
import { activeClassFunc } from '../../utils';
import { useProject } from '../../context/project';
import { useQuery } from 'react-query';
import {
  selectActiveCollection,
  selectProject,
  selectSchemaForCollection,
} from '../../context/project/selectors';
import {
  setActiveCollectionAction,
  showModalAction,
  updateCollectionAction,
} from '../../context/project/actions';
import ProjectSchemaBuilder from '../../components/projects/ProjectSchemaBuilder/ProjectSchemaBuilder';

export default function DatabaseHome() {
  const params = useParams();
  const state = useProject();
  const navigate = useNavigate();
  const project = selectProject(state);
  const projectId = project?._id;
  // const [activeCollection, setActiveCollection] = React.useState(null);
  const activeCollection = selectActiveCollection(state);
  const {
    data: collections = [],
    error,
    isLoading,
  } = useQuery(['tables', projectId], () => getTables(projectId), {
    enabled: !!projectId,
  });
  const collectionSchema = selectSchemaForCollection(
    state,
    activeCollection?._id
  );

  if (error) {
    notification.error({
      message: 'Error',
      description: error.message,
    });
  }

  const showCollectionModal = () => {
    state.dispatch(showModalAction(PROJECT_MODALS.CREATE_COLLECTION_MODAL));
  };

  useEffect(() => {
    // if (params?.collection) {
    //   const collection = collections.find(
    //     (collection) => collection._id === params.collection
    //   );
    //   state.dispatch(setActiveCollectionAction(collection));
    //   return;
    // }
    // if (collections.length > 0) {
    //   const link = getCollectionLink(collections[0]);
    //   navigate(link);
    // }
  }, [collections, params?.collection]);

  const getCollectionLink = (collection) => {
    return `/projects/${params.project}/database/collections/${collection._id}`;
  };

  const handleChange = (schema) => {
    // console.log('schema', schema);
    state.dispatch(
      updateCollectionAction({
        _id: activeCollection._id,
        schema: schema,
      })
    );
  };

  return (
    <div className="database-page">
      { false && <div>
        <div className="database-collections">
          <div className="add-collection-btn-wrap">
            <Button
              type="default"
              icon={<TbPlus />}
              className="add-collection"
              onClick={showCollectionModal}
            >
              Add Colletion
            </Button>
          </div>
          <h4>All Collections</h4>
          <ul className="collection-list">
            {collections.map((collection, index) => (
              <li key={collection._id}>
                <NavLink
                  to={getCollectionLink(collection)}
                  className={activeClassFunc}
                >
                  {collection?.title}
                </NavLink>
              </li>
            ))}
          </ul>
          {collections.length === 0 && (
            <div className="mx-3 my-1 p-3 bg-white border rounded">
              <p className="m-0 text-small small text-muted">
                You don't have any collections.
              </p>
            </div>
          )}
        </div>
        <ContentSection>
          {collectionSchema?.errors && (
            <div className="alerts-wrapper">
              {collectionSchema?.errors.map((error, index) => (
                <Alert
                  message={error.message}
                  type="error"
                  showIcon
                  className="mb-2"
                  key={index}
                />
              ))}
            </div>
          )}
          {activeCollection && (
            <>
              <h4 className="fs-5 pb-2 pt-3 ps-3">
                Update {activeCollection?.title} Schema
              </h4>
              <SchemaEditor
                schema={
                  collectionSchema?.schema || activeCollection?.schemaString
                }
                onChange={handleChange}
              />
            </>
          )}
          {collections.length === 0 && (
            <Empty description="Create your first Collection!" />
          )}
        </ContentSection>
      </div> }
      <ProjectSchemaBuilder collections={collections} />
    </div>
  );
}
