import { createContext, useContext, useEffect, useReducer } from 'react';
import projectReducer, { initialState } from './reducer';
import { useParams } from 'react-router-dom';
import { fetchProjectEffect } from './effects';

const ProjectContext = createContext();

export const ProjectProvider = ({ children }) => {
  const [state, dispatch] = useReducer(projectReducer, initialState);
  const params = useParams();
  const projectId = params.project;

  useEffect(() => {
    if (projectId) {
      fetchProjectEffect(dispatch, projectId);
    }
  }, [projectId]);

  // ... other methods, where you can dispatch actions directly or use effects

  return (
    <ProjectContext.Provider value={{ ...state, dispatch /*, ...methods */ }}>
      {children}
    </ProjectContext.Provider>
  );
};

export const useProject = () => useContext(ProjectContext);