import React from 'react';
import ProjectSection from '../../components/sections/ProjectSection/ProjectSection';
// import { useProject } from '../../context/projectContext';
import SchemaEditor from '../../containers/SchemaEditor/SchemaEditor';
import { useProject } from '../../context/project';

export default function ProjectDataModels() {
  const { project, setSaved, schema, setSchema } = useProject();

  console.log('project', project, schema);

  const handleSchemaChange = (value) => {
    console.log(value);
    setSchema(value);
    setSaved(false);
  };

  return (
    <ProjectSection title={'Editor'}>
      <SchemaEditor
        schema={schema}
        onChange={handleSchemaChange}
      />
    </ProjectSection>
  );
}
