import React from 'react';

export default function SettingsPage({ title, description, children }) {
  return (
    <div className="setttings-page-main">
      <div className="setting-page-heading">
        <h3>{title}</h3>
        <p>{description} </p>
        {/* <hr /> */}
      </div>
      <div className="setting-page-content">{children}</div>
    </div>
  );
}
