import classNames from 'classnames';
import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import './DashboardHeader.scss'
import { Spin } from 'antd';

export default function DashboardHeader({
  title,
  heading,
  links = [],
  actions,
  deploying,
}) {
  const location = useLocation();
  const pathname = location.pathname

  return (
    <div className="page-header">
      <div className="container">
        <div className="row align-items-center">
          <div className="col-md-4">
            <div className="content-header">
              <h1 className="page-title">{title}</h1>
              {heading && <p>{heading}</p>}
            </div>
          </div>
          <div className="col-md-4 text-center">
            { deploying && <div className="deploying-changes d-inline-flex align-items-center">
              <Spin />
              Deploying Changes...
            </div> }
          </div>
          <div className="col-md-4 text-end">
            <div className="dashboard-actions">{actions}</div>
          </div>
        </div>
        {links.length > 0 && (
          <div className="row">
            <div className="col-md-12">
              <ul className="navigation">
                {links.map((link, index) => (
                  <li
                    className={classNames({
                      active: pathname === link.href,
                    })}
                    key={index}
                  >
                    <Link to={link.href || '/'}>{link.title}</Link>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
