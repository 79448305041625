import { Card, Col, Row, Space, Statistic } from 'antd';
import React from 'react';
import GettingStartChecklist from '../components/sections/GettingStartedChecklist/GettingStartedChecklist';
import DashboardHeader from '../components/sections/DashboardHeader/DashboardHeader';
import UIButton from '../components/ui/UIButton/UIButton';
import PageContent from '../components/sections/PageContent/PageContent';
import DashboardContainer from '../containers/DashboardContainer/DashboardContainer';
import { TbPlus } from 'react-icons/tb';
import useModal from 'antd/es/modal/useModal';
import { MODALS, useModals } from '../context/modalContext';

export default function Dashboard() {
  const { showModal } = useModals();

  const handleAddProjectClick = () => {
    console.log('Add project clicked');
    showModal(MODALS.SELECT_NEW_PROJECT_TYPE);
  };

  return (
    <div className="dashboard-page">
      <DashboardHeader
        title={'Dashboard'}
        heading={
          "Welcome back, Samar. Here's what's happening with your projects."
        }
        links={[
          {
            title: 'Dashboard',
            href: '/',
          },
          {
            title: 'Performance',
            href: '/web/performance',
          },
          {
            title: 'Alerts',
            href: '/web/alerts',
          },
          {
            title: 'Notifications',
            href: '/notifications',
          },
            {
              title: 'Inbox',
              href: '/web/inbox',
            },
        ]}
        actions={
          <Space size={10}>
            <UIButton title={'Pick a Template'} link={'/templates'} icon={<TbPlus />} />
            <UIButton
              title={'New Project'}
              theme="primary"
              onClick={handleAddProjectClick}
            />
          </Space>
        }
      />
      <PageContent>
        <div className="container">
          <DashboardContainer />
        </div>
      </PageContent>
    </div>
  );
}
