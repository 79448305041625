import React from 'react';
import { Form, Input, Button, Checkbox, Row, Col, Empty, Select } from 'antd';
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import './RouteInputForm.scss';
import ReactAce from 'react-ace';
import AceEditorWrapper from '../AceEditorWrapper/AceEditorWrapper';

const RouteInputForm = ({ onSubmit }) => {

  const [input, setInput] = React.useState('');

  const handleChange = (values) => {
    console.log('Form Values:', values);
    setInput(values);
  };

  const handleSubmit = (values) => {
    console.log('Form Values:', values);
    try {
      const json = JSON.parse(input);
      console.log('JSON', json);
      onSubmit(json);
    } catch (error) {
      console.log('Error parsing JSON', error);
    }
  }

  return (
    <div>
      <AceEditorWrapper handleChange={handleChange} />
      <div style={{ marginTop: '20px' }}>
        <Button type="primary" onClick={handleSubmit}>
          Save Input
        </Button>
      </div>
    </div>
  )

  // return (
  //   <Form
  //     onFinish={handleSubmit}
  //     initialValues={{ rows: [] }}
  //     className="route-input-form"
  //   >
  //     <Row
  //       gutter={16}
  //       style={{ marginBottom: '10px' }}
  //       className="table-header"
  //     >
  //       <Col span={5}>
  //         <strong>Label</strong>
  //       </Col>
  //       <Col span={5}>
  //         <strong>Type</strong>
  //       </Col>
  //       <Col span={3}>
  //         <strong>Required</strong>
  //       </Col>
  //       <Col span={5}>
  //         <strong>Default Value</strong>
  //       </Col>
  //       <Col span={4}>
  //         <strong>Value</strong>
  //       </Col>
  //     </Row>

  //     <Form.List name="rows">
  //       {(fields, { add, remove }) => (
  //         <>
  //           {fields.map((field) => (
  //             <Row gutter={16} align="middle" key={field.key}>
  //               <Col span={5}>
  //                 <Form.Item
  //                   {...field}
  //                   name={[field.name, 'label']}
  //                   key={[field.key, 'label']}
  //                   rules={[{ required: true, message: 'Missing label' }]}
  //                 >
  //                   <Input placeholder="Label" />
  //                 </Form.Item>
  //               </Col>
  //               <Col span={5}>
  //                 <Form.Item
  //                   {...field}
  //                   name={[field.name, 'label']}
  //                   key={[field.key, 'label']}
  //                   rules={[{ required: true, message: 'Missing label' }]}
  //                 >
  //                   <Select placeholder="Type">
  //                     <Select.Option value="string">String</Select.Option>
  //                     <Select.Option value="number">Number</Select.Option>
  //                     <Select.Option value="boolean">Boolean</Select.Option>
  //                     <Select.Option value="date">Date</Select.Option>
  //                     <Select.Option value="datetime">DateTime</Select.Option>
  //                   </Select>
  //                 </Form.Item>
  //               </Col>

  //               <Col span={3}>
  //                 <Form.Item
  //                   {...field}
  //                   name={[field.name, 'required']}
  //                   valuePropName="checked"
  //                   key={[field.key, 'required']}
  //                 >
  //                   <Checkbox />
  //                 </Form.Item>
  //               </Col>

  //               <Col span={5}>
  //                 <Form.Item
  //                   {...field}
  //                   name={[field.name, 'defaultValue']}
  //                   key={[field.key, 'defaultValue']}
  //                 >
  //                   <Input placeholder="Default Value" />
  //                 </Form.Item>
  //               </Col>

  //               <Col span={4}>
  //                 <Form.Item
  //                   {...field}
  //                   name={[field.name, 'value']}
  //                   key={[field.key, 'value']}
  //                 >
  //                   <Input placeholder="Value" />
  //                 </Form.Item>
  //               </Col>

  //               <Col flex="none">
  //                 <MinusCircleOutlined onClick={() => remove(field.name)} />
  //               </Col>
  //             </Row>
  //           ))}
  //           {fields.length === 0 && (
  //             <Row>
  //               <Col span={24}>
  //                 <div className="p-3 bg-light">
  //                   <Empty description="No Fields Defined">
  //                     <Button
  //                       type="dashed"
  //                       onClick={() => add()}
  //                       icon={<PlusOutlined />}
  //                     >
  //                       Add Input Field
  //                     </Button>
  //                   </Empty>
  //                 </div>
  //               </Col>
  //             </Row>
  //           )}
  //           <div className="pt-3 mt-3 border-top"></div>
  //           {fields.length > 0 && (
  //             <Form.Item>
  //               <Button
  //                 type="dashed"
  //                 onClick={() => add()}
  //                 icon={<PlusOutlined />}
  //               >
  //                 Add Input Field
  //               </Button>
  //             </Form.Item>
  //           )}
  //         </>
  //       )}
  //     </Form.List>

  //     <Form.Item className="input-form-actions">
  //       <Button type="primary" htmlType="submit">
  //         Save Input
  //       </Button>
  //     </Form.Item>
  //   </Form>
  // );
};

export default RouteInputForm;
