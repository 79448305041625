import React from 'react';
import './SingleRoute.scss';
import classNames from 'classnames';
import { TbCopy, TbEdit, TbLock, TbTrash } from 'react-icons/tb';
import { Switch, Tooltip } from 'antd';
import { useNavigate } from 'react-router-dom';
import { useProject } from '../../../context/project';

export default function SingleRoute({
  id,
  method,
  url,
  inputs = 0,
  functions = 0,
  auth,
}) {
  const { project } = useProject();
  const projectId = project?._id;
  const navigate = useNavigate();
  const handleChange = (checked) => {
    console.log(`switch to ${checked}`);
  }

  const editRoute = () => {
    console.log('edit route');
    navigate(`/projects/${projectId}/routes/edit?route=${id}`);
  }

  return (
    <div className="single-route">
      <div
        className={classNames(
          'single-route__method',
          `method_${method.toLowerCase()}`
        )}
      >
        {method.toUpperCase()}
      </div>
      <div className="single-route__url">{url}</div>
      <div className="single-route__enable-disble">
        <Switch defaultChecked={true} size='small' onChange={handleChange} />
      </div>
      <div className="single-route__details">
        <div className="single-route__single-detail">
          <div className="det-count">
            {inputs > 0 ? inputs.toString().padStart(2, '0') : '0'}
          </div>
          <div className="det-label">Inputs</div>
        </div>
        <div className="single-route__single-detail">
          <div className="det-count">
            {functions > 0 ? functions.toString().padStart(2, '0') : '0'}
          </div>
          <div className="det-label">Functions</div>
        </div>
        <div className="single-route__single-detail">
          <div className="det-count">
            <Tooltip title="Copy Link" style={{ fontSize: '11px' }}>
              <TbCopy />
            </Tooltip>
          </div>
        </div>
        <div
          className={classNames('single-route__single-detail', {
            disabled: !auth,
          })}
        >
          <div className="det-count">
            {auth && (
              <Tooltip title="Copy Link" style={{ fontSize: '11px' }}>
                <TbLock />
              </Tooltip>
            )}
            {!auth && <TbLock />}
          </div>
        </div>
      </div>
      <div className="single-route__actions">
        <Tooltip title="Edit" style={{ fontSize: '11px' }}>
          <button onClick={editRoute}>
            <TbEdit />
          </button>
        </Tooltip>
        <Tooltip title="Delete" style={{ fontSize: '11px' }}>
          <button>
            <TbTrash />
          </button>
        </Tooltip>
      </div>
    </div>
  );
}
