import { Form, Switch } from 'antd';
import React from 'react';
import './ModalOption.scss'

export default function ModalOption({ id, title, children, checked }) {
  const [enabled, setEnabled] = React.useState(checked);
  return (
    <div className="modal-option">
      <label htmlFor={id} className="modal-option-title">
        <Form.Item name={'enableMobileLogin'}>
          <Switch id={id} size="small" onChange={() => setEnabled(!enabled)}  defaultChecked={checked}/>
          <span>{title}</span>
        </Form.Item>
      </label>
      {enabled && <div className="modal-option-content">{children}</div>}
    </div>
  );
}
