import { Button, Col, Form, Input, Row, Switch, message } from 'antd';
import React, { useEffect } from 'react';
import { useMutation, useQueryClient } from 'react-query';
import { createSingleTableData, updateSingleTableData } from '../../../services/api.service';

export default function ProjectCRMCreateForm({ project, collection, schemaArray = [], record, onCreate, onClose }) {

  const queryClient = useQueryClient();
  const [form] = Form.useForm();
  const isEditing = record && Object.keys(record).length > 0;

  const createMutation = useMutation(({ project, collection, values }) => createSingleTableData(project, collection, values), {
    onSuccess: (data) => {
      queryClient.invalidateQueries(['tableData', project, collection]);
      message.open({
        type: 'success',
        content: 'Data updated successfully',
        duration: 5,
      });
      onCreate();
    },
  });

  const updateMutation = useMutation(({ project, collection, values }) => updateSingleTableData(project, collection, record._id, values), {
    onSuccess: (data) => {
      queryClient.invalidateQueries(['tableData', project, collection]);
      message.open({
        type: 'success',
        content: 'Data updated successfully',
        duration: 5,
      });
      onCreate();
    },
  });

  const onFinish = (values) => {
    console.log('Received values of form: ', values);
    if(!isEditing) {
      createMutation.mutate({
        project,
        collection,
        values
      });
    } else {
      updateMutation.mutate({
        project,
        collection,
        values
      });
    }
  }

  useEffect(() => {
    if(record && Object.keys(record).length > 0) {
      console.log('record', record);
      schemaArray.forEach((item) => { 
        if(record[item.name]) {
          form.setFieldsValue({
            [item.name]: record[item.name]
          });
        }
      }
      );
      // form.setFields(record);
    }
  }, [form, schemaArray, record]);

  return (
    <Form
      layout="vertical"
      onFinish={onFinish}
      form={form}
    >
      {schemaArray.map((item) => {
        return (
          <Form.Item
            name={item.name}
            label={item.title}
            required={item.required}
          >
            {item.type === 'String' && <Input.TextArea size="large" />}
            {item.type === 'Number' && <Input type="number" size="large" />}
            {item.type === 'Boolean' && <Switch size="large" />}
          </Form.Item>
        );
      })}
      <Form.Item>
        <Row justify="space-between">
          <Col>
            <Button htmlType="submit" type="primary">
              { isEditing ? 'Update' : 'Create' } Record
            </Button>
          </Col>
          <Col>
            <Button htmlType="button" onClick={onClose}>Cancel</Button>
          </Col>
        </Row>
      </Form.Item>
    </Form>
  );
}
