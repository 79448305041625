import React from 'react';
import { TbChevronRight } from 'react-icons/tb';

export default function ProjectSection({ title, subTitle, actions, children }) {
  return (
    <div className="project-section-page">
      <div className="project-header">
        <div className="container-fluid">
          <div className="row align-items-center">
            <div className="col-md-4">
              <div className='d-flex align-items-center'>
                <h4>{title}</h4>
                { subTitle && (
                  <>
                    <TbChevronRight className='mx-2' />
                    <h4>{ subTitle }</h4>
                  </>
                )}
              </div>
            </div>
            <div className="col-md-4 text-center">
              {/* <div className="nav-links">
                <ul>
                  <li>
                    <a href="#">Schema</a>
                  </li>
                  <li>
                    <a href="#">Routes</a>
                  </li>
                  <li>
                    <a href="#">Data</a>
                  </li>
                </ul>
              </div> */}
            </div>
            <div className="col-md-4 text-end">{actions}</div>
          </div>
        </div>
      </div>
      <div className="project-main-window">{children}</div>
    </div>
  );
}
