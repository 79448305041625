import React, { useEffect } from 'react';
import AceEditorWrapper from './AceEditorWrapper/AceEditorWrapper';
import { Button, Col, Form, Row, Select, message } from 'antd';
import { DEFAULT_ROUTE_CONFIG } from '../../../config/constants';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { createRouteApi, getRouteGroupsApi, getSingleRouteApi, getTables, updateSingleRouteApi } from '../../../services/api.service';
import { useProject } from '../../../context/project';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { convertRouteToRouteJson } from '../../../utils/routes';

export default function ProjectCreateRouteAlt({ edit = false }) {
  const navigate = useNavigate();
  const [input, setInput] = React.useState('');
  const [valid, setValid] = React.useState(false);
  const queryClient = useQueryClient();
  const { project } = useProject();
  const projectId = project?._id;

  const [searchParams, setSearchParams] = useSearchParams();

  const [routeData, setRouteData] = React.useState({
    table: null,
    routeGroup: null,
  });
  const routeId = searchParams.get('route');

  const { data: routeGroups = [] } = useQuery(['routeGroups', projectId], () => getRouteGroupsApi(projectId), {
    enabled: !!projectId,
  });
  const { data: tables = [] } = useQuery(['tables', projectId], () => getTables(projectId), {
    enabled: !!projectId,
  });

  const { data:route = null, error, isLoading } = useQuery(['route', edit], () => {
    return getSingleRouteApi(projectId, routeId);
  }, {
    enabled: !!(projectId && edit && routeId),
  });
  console.log(routeId, route);

  useEffect(() => {
    if(edit && route) {
      setRouteData({
        table: route.functions[0].table,
        routeGroup: route.routeGroup,
      });
      setInput(JSON.stringify(convertRouteToRouteJson(route), null, 2));
    }
  }, [route, edit]);

  const mutation = useMutation(createRouteApi, {
    onSuccess: (data) => {
      console.log('Success', data);
      message.open({
        type: 'success',
        content: 'Route created successfully',
        duration: 5,
      });
      queryClient.invalidateQueries('routes');
      navigate(`/projects/${project?._id}/routes`);
    },
    onError: (error) => {
      console.log('Error', error);
      message.open({
        type: 'error',
        content: 'Error creating route: ' + error.message,
        duration: 5,
      });
    },
  });

  const handleRouteChange = (values) => {
    setInput(values);
    try {
      JSON.parse(values);
      setValid(true);
    } catch (error) {
      setValid(false);
    }
  };

  const handleCreateRoute = () => {
    if(edit) {
      console.log('Update Route');
      try {
        const json = JSON.parse(input);
        const inputs = json?.action?.inputs || [];
        const functions = json?.action?.functions || [];
        updateSingleRouteApi(projectId, routeId, {
          path: json.path,
          method: json.method,
          routeGroup: routeData.routeGroup,
          inputs: inputs.map((input) => ({
            label: input.label,
            type: input.type,
            required: input.required,
            hasStaticValue: input.hasStaticValue,
            in: input.inputType,
            inputType: input.type.toLowerCase(),
          })),
          functions: functions.map((func) => ({
            functionType: func.type,
            table: routeData.table,
            filter: func.filter,
          }))
        }).then((res) => {
          console.log(res);
          message.open({
            type: 'success',
            content: 'Route updated successfully',
            duration: 5,
          });
          queryClient.invalidateQueries('routes');
          navigate(`/projects/${project?._id}/routes`);
        }).catch((err) => {
          console.log(err);
          message.open({
            type: 'error',
            content: 'Error updating route: ' + err.message,
            duration: 5,
          });
        });
      } catch (error) {
        console.log('Error', error);
      }
    } else {
      console.log('Create Route');
      try {
        const json = JSON.parse(input);
        console.log('JSON', json);
        const inputs = json?.action?.inputs || [];
        const functions = json?.action?.functions || [];
        mutation.mutate({
          projectId: project?._id,
          body: {
            path: json.path,
            method: json.method,
            routeGroup: routeData.routeGroup,
            inputs: inputs.map((input) => ({
              label: input.label,
              type: input.type,
              required: input.required,
              hasStaticValue: input.hasStaticValue,
              in: input.inputType,
              inputType: input.type.toLowerCase(),
            })),
            functions: functions.map((func) => ({
              functionType: func.type,
              table: routeData.table,
              filter: func.filter,
            })),
          },
        });
      } catch (error) {
        console.log('Error', error);
      }
    }
  };

  if(edit && !route) {
    return (
      <div>
        Loading...
      </div>
    )
  }

  return (
    <div style={{ width: '100%' }}>
      <Row>
        <Col span={18}>
          <AceEditorWrapper
            handleChange={handleRouteChange}
            initialValue={!edit ? JSON.stringify(DEFAULT_ROUTE_CONFIG, null, 2) : JSON.stringify(convertRouteToRouteJson(route), null, 2)}
          />
        </Col>
        <Col span={6}>
          <div style={{ padding: '30px' }}>
            <h4>Route Config</h4>
            <p>
              This is the configuration for your route. You can edit the JSON
              below to customize your route.
            </p>
            <div style={{ marginTop: '20px' }}>
              <Form.Item>
                <Select
                  value={routeData.routeGroup}
                  placeholder="Select Route Group"
                  style={{ width: '100%' }}
                  onChange={(val) => {
                    console.log(val);
                    setRouteData({
                      ...routeData,
                      routeGroup: val,
                    });
                  }}
                >
                  { routeGroups.map((group, index) => (
                    <Select.Option value={group._id}>{ group.title}</Select.Option>
                  ))}
                </Select>
              </Form.Item>
              <Form.Item>
                <Select
                  value={routeData.table}
                  placeholder="Select Table" style={{ width: '100%' }} onChange={(val) => {
                  console.log(val);
                  setRouteData({
                    ...routeData,
                    table: val,
                  });
                }}>
                  { tables.map((table, index) => (
                    <Select.Option value={table._id}>{ table.title}</Select.Option>
                  ))}
                </Select>
              </Form.Item>
              <div style={{ marginTop: '20px', textAlign: 'left' }}>
                <Button
                  type="primary"
                  onClick={handleCreateRoute}
                  size="middle"
                  disabled={!valid}
                >
                  { edit ? 'Update Route' : 'Create Route'}
                </Button>
              </div>
            </div>
          </div>
        </Col>
      </Row>
    </div>
  );
}
