import { Modal } from 'antd';
import React from 'react';
import { MODALS, useModals } from '../../context/modalContext';
import './SelectNewProjectTypeModal.scss';
import { TbCirclePlus, TbTemplate } from 'react-icons/tb';
import { useNavigate } from 'react-router-dom';

const SelectNewProjectType = ({ onHide }) => {
  const navigate = useNavigate();
  const { showModal } = useModals();

  const handleNewFromScratch = () => {
    console.log('Add project clicked');
    onHide();
    showModal(MODALS.CREATE_PROJECT_MODAL, true);
  }

  const handleFromTemplate = () => {
    onHide();
    navigate('/templates');
  }

  return <div className='select-new-project-type'>
    <div className="select-project-header">
      <h2>Create a New Project</h2>
    </div>
    <div className="select-project-content">
      <div className="row">
        <div className="col-md-6">
          <div className="card project-type-card" onClick={handleNewFromScratch}>
            <div className="img-wrapper">
              <TbCirclePlus size={60} strokeWidth={1} color='#6610c1' />
            </div>
            <div className="main-text mt-5">
              <h3>Create a new Project from Scratch</h3>
            </div>
          </div>
        </div>
        <div className="col-md-6">
          <div className="card project-type-card" onClick={handleFromTemplate}>
            <div className="img-wrapper">
              <TbTemplate size={60} strokeWidth={1} color='#6610c1' />
            </div>
            <div className="main-text mt-5">
              <h3>Start Project from existing Template</h3>
            </div>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-md-12">
          <div className="cancel-container">
            <button className='cancel' onClick={onHide}>Cancel</button>
          </div>
        </div>
      </div>
    </div>
  </div>;
};

export default function SelectNewProjectTypeModal() {
  const { modals, hideModal } = useModals();
  const { showSelectNewProjectType, data } = modals;

  const hideCurrentModal = () => {
    hideModal(MODALS.SELECT_NEW_PROJECT_TYPE);
  };

  return (
    <Modal
      open={showSelectNewProjectType}
      width={600}
      footer={false}
      // title="Create a New Project"
      onCancel={hideCurrentModal}
      destroyOnClose={true}
      title={false}
    >
      <SelectNewProjectType onHide={hideCurrentModal} data={data} />
    </Modal>
  );
}
