export const ACTION_TYPES = {
    FETCH_PROJECT: 'FETCH_PROJECT',
    SET_PROJECT: 'SET_PROJECT',
    SET_SAVED: 'SET_SAVED',
    SET_SCHEMA: 'SET_SCHEMA',
    SHOW_MODAL: 'SHOW_MODAL',
    HIDE_MODAL: 'HIDE_MODAL',
    SET_ACTIVE_COLLECTION: 'SET_ACTIVE_COLLECTION',
    UPDATE_COLLECTION: 'UPDATE_COLLECTION',
    REMOVE_UPDATED_COLLECTION: 'REMOVE_UPDATED_COLLECTION',
    UPDATE_DEPLOYING_STATUS: 'UPDATE_DEPLOYING_STATUS',
}

export const setProjectAction = (project) => ({
    type: ACTION_TYPES.SET_PROJECT,
    payload: project,
});

export const fetchProjectAction = () => ({
    type: ACTION_TYPES.FETCH_PROJECT,
    payload: null,
});

export const setSavedAction = (saved) => ({
    type: ACTION_TYPES.SET_SAVED,
    payload: saved,
});

export const setSchemaAction = (schema) => ({
    type: ACTION_TYPES.SET_SCHEMA,
    payload: schema,
});

export const showModalAction = (modal) => ({
    type: ACTION_TYPES.SHOW_MODAL,
    payload: modal,
});

export const hideModalAction = (modal) => ({
    type: ACTION_TYPES.HIDE_MODAL,
    payload: modal,
});

export const setActiveCollectionAction = (collection) => ({
    type: ACTION_TYPES.SET_ACTIVE_COLLECTION,
    payload: collection,
});

export const updateCollectionAction = (collection) => ({
    type: ACTION_TYPES.UPDATE_COLLECTION,
    payload: collection,
});

export const removeUpdatedCollectionAction = (collectionId) => ({
    type: ACTION_TYPES.REMOVE_UPDATED_COLLECTION,
    payload: collectionId,
});

export const updateDeployingStatus = (status) => ({
    type: ACTION_TYPES.UPDATE_DEPLOYING_STATUS,
    payload: status,
});