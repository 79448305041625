import React from 'react';
import { Form, Input, Button, Select, Space, Row } from 'antd';
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import './QueryBuilder.scss'

const { Option } = Select;

const QueryBuilder = () => {
  const handleSubmit = (values) => {
    const query = values.conditions.reduce((acc, condition) => {
      acc[condition.field] = { [condition.operator]: condition.value };
      return acc;
    }, {});
    console.log('Generated Query:', query);
  };

  return (
    <div className="query-builder">
      <Form onFinish={handleSubmit} initialValues={{ conditions: [{}] }} layout='vertical'>
        <Form.List name="conditions">
          {(fields, { add, remove }) => (
            <>
              {fields.map((field) => (
                <div className="query-builder-row" key={field.key}>
                  <Form.Item
                    {...field}
                    name={[field.name, 'field']}
                    fieldKey={[field.fieldKey, 'field']}
                    rules={[{ required: true, message: 'Missing field' }]}
                    label="Field"
                  >
                    <Input placeholder="Field Name" />
                  </Form.Item>
                  <Form.Item
                    {...field}
                    name={[field.name, 'operator']}
                    fieldKey={[field.fieldKey, 'operator']}
                    rules={[{ required: true, message: 'Missing operator' }]}
                    label="Operator"
                  >
                    <Select placeholder="Select Operator">
                      <Option value="$eq">Equals</Option>
                      <Option value="$gte">Greater Than or Equals</Option>
                      <Option value="$lte">Less Than or Equals</Option>
                      {/* Add more operators as needed */}
                    </Select>
                  </Form.Item>
                  <Form.Item
                    {...field}
                    name={[field.name, 'value']}
                    fieldKey={[field.fieldKey, 'value']}
                    rules={[{ required: true, message: 'Missing value' }]}
                    label="Value"
                  >
                    <Input placeholder="Value" />
                  </Form.Item>
                  <MinusCircleOutlined onClick={() => remove(field.name)} />
                </div>
              ))}
              <Form.Item>
                <Button
                  type="dashed"
                  onClick={() => add()}
                  block
                  icon={<PlusOutlined />}
                >
                  Add Condition
                </Button>
              </Form.Item>
            </>
          )}
        </Form.List>
        {/* <Form.Item>
          <Button type="primary" htmlType="submit">
            Save Query
          </Button>
        </Form.Item> */}
      </Form>
    </div>
  );
};

export default QueryBuilder;
