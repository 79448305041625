import React from 'react'
import CreateProjectModal from './CreateProjectModal'
import SelectNewProjectTypeModal from './SelectNewProjectTypeModal/SelectNewProjectTypeModal'
import './Modals.scss';

export default function ModalWrapper() {
  return (
    <div>
        <CreateProjectModal />
        <SelectNewProjectTypeModal />
    </div>
  )
}
