import React from 'react';
import ProjectSection from '../../components/sections/ProjectSection/ProjectSection';
import ContentSection from '../../components/sections/ContentSection/ContentSection';
import { Button, Col, Form, Input, Modal, Row } from 'antd';
import { TbCheck } from 'react-icons/tb';
import { useProject } from '../../context/project';
import { deleteProjectApi, updateProjectApi } from '../../services/api.service';
import { fetchProjectEffect } from '../../context/project/effects';
import { useNavigate } from 'react-router-dom';

export default function ProjectSettings() {
  const { project, dispatch } = useProject();
  const navigate = useNavigate();
  const [showDeleteModal, setShowDeleteModal] = React.useState(false);

  if (!project) return <></>;

  const handleUpdate = (values) => {
    console.log(values);
    updateProjectApi(project._id, values).then(({ data: res }) => {
      fetchProjectEffect(dispatch, project._id);
    });
  };

  const handleDeleteProject = () => {
    console.log('delete project');
    deleteProjectApi(project._id).then(({ data: res }) => {
      fetchProjectEffect(dispatch, project._id);
      navigate('/projects');
    });
  };

  return (
    <ProjectSection title={'Settings'}>
      <ContentSection>
        <div className="p-5 bg-white">
          <Row>
            <Col span={12}>
              <Form layout="vertical" onFinish={handleUpdate}>
                <Form.Item label={'Project Name'} name={'title'}>
                  <Input
                    placeholder="Project Name"
                    size="large"
                    defaultValue={project?.title}
                  />
                </Form.Item>
                <Form.Item label={'Project URL'}>
                  <Input
                    placeholder="Project Name"
                    size="large"
                    defaultValue={project?.url}
                    disabled
                  />
                </Form.Item>
                <Form.Item>
                  <Button
                    type="primary"
                    htmlType="submit"
                    size="large"
                    icon={<TbCheck />}
                  >
                    Update Project
                  </Button>
                </Form.Item>
              </Form>
              <hr className="my-5" />
              <div className="p-4 border bg-light border">
                <h4 className="fs-5">Delete Project</h4>
                <p>
                  Deleting this project will remove all data associated with it.
                  This action cannot be undone.
                </p>
                <Button type="primary" danger onClick={() => setShowDeleteModal(true)}>
                  Delete Project
                </Button>
              </div>
            </Col>
          </Row>
        </div>
        <Modal
          title="Delete this project?"
          open={showDeleteModal}
          okText="Yes, Delete this project"
          okType="danger"
          onOk={handleDeleteProject}
          onCancel={() => setShowDeleteModal(false)}
        >
          <p>Are you sure you want to delete this project?</p>
        </Modal>
      </ContentSection>
    </ProjectSection>
  );
}
