import React from 'react';
import ProjectSection from '../../components/sections/ProjectSection/ProjectSection';
// import { useProject } from '../../context/projectContext';
import { Button } from 'antd';
import { useProject } from '../../context/project';

export default function ProjectAPIDocs() {
  const { project } = useProject();

  const handleOnClick = () => {
    window.open(project?.url + '/api-docs', '_blank');
  };

  return (
    <ProjectSection
      title={'Documentation'}
      actions={<Button onClick={handleOnClick}>Open in new Tab</Button>}
    >
      <iframe
        title="API Docs"
        src={project?.url + '/api-docs'}
        className="api-docs-iframe"
      ></iframe>
    </ProjectSection>
  );
}
