import './App.scss';
import {
  BrowserRouter,
  Navigate,
  Route,
  RouterProvider,
  Routes,
  createBrowserRouter,
} from 'react-router-dom';
import Dashboard from './routes/Dashboard';
import Notification from './routes/Notification';
import MainLayout from './layout/MainLayout/MainLayout';
import Login from './routes/auth/Login';
import Signup from './routes/auth/Signup';
import ForgetPassword from './routes/auth/ForgetPassword';
import ResetPassword from './routes/auth/ResetPassword';
import { useAuth } from './context/authContext';
import AuthLayout from './layout/AuthLayout/AuthLayout';
import { Spin } from 'antd';
import ProjectHome from './routes/projects/ProjectHome';
import TemplatesHome from './routes/projects/TemplatesHome';
import ModalWrapper from './modals/ModalWrapper';
import ProjectHomepage from './routes/projecthome/ProjectSummary';
import ProjectHomeLayout from './routes/projecthome/ProjectHomeLayout';
import ProjectDataModels from './routes/projecthome/ProjectDataModels';
import ProjectSummary from './routes/projecthome/ProjectSummary';
import ProjectAuthentication from './routes/projecthome/ProjectAuthentication';
import ProjectSettings from './routes/projecthome/ProjectSettings';
import ProjectAPIDocs from './routes/projecthome/ProjectAPIDocs';
import { useEffect } from 'react';
import AccountSettings from './routes/settings/AccountSettings';
import SettingsLayout from './routes/settings/SettingsLayout';
import PasswordSettings from './routes/settings/PasswordSettings';
import ProjectDatabase from './routes/projecthome/ProjectDatabase';
import ProjectStorage from './routes/projecthome/ProjectStorage';
import ProjectSupport from './routes/projecthome/ProjectSupport';
import { QueryClient, QueryClientProvider } from 'react-query';
import OrganizationSettings from './routes/settings/OrganizationSettings'
import ProjectExport from './routes/projecthome/ProjectExport';
import ProjectPayments from './routes/projecthome/ProjectPayments';
import ProjectRoutes from './routes/projecthome/ProjectRoutes';
import CreateProjectRoute from './routes/projecthome/CreateProjectRoute';
import ProjectDataCRM from './routes/projecthome/ProjectDataCRM';
import ProjectDataCRMCollection from './routes/projecthome/ProjectDataCRMCollection';

export const queryClient = new QueryClient();

// const router = createBrowserRouter([
//   {
//     path: '/',
//     element: <Dashboard />,
//     children: [
//       {
//         path: '/notifications',
//         element: <div>Home</div>,
//       }
//     ]
//   },
//   {
//     path: '/notification',
//     element: <Notification />,
//   }
// ]);

function App() {
  const { user } = useAuth();

  useEffect(() => {
    if (user === null) {
      console.log('user is null', window.location.href);
      // window.location.href = '/login';
    }
  }, [user]);

  if (user === undefined) {
    return (
      <div className="App">
        <Spin />
      </div>
    );
  }

  return (
    <div className="App">
      {user && (
        <BrowserRouter>
          <QueryClientProvider client={queryClient}>
            <MainLayout>
              <Routes>
                <Route path="/" element={<Dashboard />} />
                <Route path="/projects" element={<ProjectHome />} />
                <Route
                  path="/projects/:project"
                  element={<ProjectHomeLayout />}
                >
                  <Route path="" element={<ProjectSummary />} />
                  <Route
                    path="/projects/:project/editor"
                    element={<ProjectDataModels />}
                  />
                  <Route
                    path="/projects/:project/editor"
                    element={<ProjectDataModels />}
                  />
                  <Route
                    path="/projects/:project/database"
                    element={<ProjectDatabase />}
                  />
                  <Route
                    path="/projects/:project/data"
                    element={<ProjectDataCRM />}
                  />
                  <Route
                    path="/projects/:project/data/:collection"
                    element={<ProjectDataCRMCollection />}
                  />
                  <Route
                    path="/projects/:project/database/collections/:collection"
                    element={<ProjectDatabase />}
                  />
                  <Route
                    path="/projects/:project/database/collections/:collection/schema"
                    element={<ProjectDatabase />}
                  />
                  <Route
                    path="/projects/:project/database/collections/:collection/routes"
                    element={<ProjectDatabase />}
                  />
                  <Route
                    path="/projects/:project/routes"
                    element={<ProjectRoutes />}
                  />
                  <Route
                    path="/projects/:project/routes/new"
                    element={<CreateProjectRoute />}
                  />
                  <Route
                    path="/projects/:project/routes/edit"
                    element={<CreateProjectRoute edit={true} />}
                  />
                  <Route
                    path="/projects/:project/storage"
                    element={<ProjectStorage />}
                  />
                  <Route
                    path="/projects/:project/support"
                    element={<ProjectSupport />}
                  />
                  <Route
                    path="/projects/:project/export"
                    element={<ProjectExport />}
                  />
                  <Route
                    path="/projects/:project/authentication"
                    element={<ProjectAuthentication />}
                  />
                  <Route
                    path="/projects/:project/payments"
                    element={<ProjectPayments />}
                  />
                  <Route
                    path="/projects/:project/api-docs"
                    element={<ProjectAPIDocs />}
                  />
                  <Route
                    path="/projects/:project/settings"
                    element={<ProjectSettings />}
                  />
                </Route>
                <Route path="/templates" element={<TemplatesHome />} />
                <Route path="/settings" element={<SettingsLayout />}>
                  <Route
                    path=""
                    element={<Navigate to={'/settings/account'} />}
                  />
                  <Route
                    path="/settings/account"
                    element={<AccountSettings />}
                  />
                  <Route
                    path="/settings/password"
                    element={<PasswordSettings />}
                  />
                  <Route path="/settings/plan" element={<AccountSettings />} />
                  <Route
                    path="/settings/organization"
                    element={<OrganizationSettings />}
                  />
                  <Route
                    path="/settings/delete"
                    element={<AccountSettings />}
                  />
                </Route>
              </Routes>
            </MainLayout>
            <ModalWrapper />
            {/* The rest of your app components */}
          </QueryClientProvider>
        </BrowserRouter>
      )}

      {!user && (
        <BrowserRouter>
          <AuthLayout>
            <Routes>
              <Route path="/" element={<Navigate to={'/login'} />} />
              <Route path="/login" element={<Login />} />
              <Route path="/signup" element={<Signup />} />
              <Route path="/forgot-password" element={<ForgetPassword />} />
              <Route path="/reset-password/:token" element={<ResetPassword />} />
            </Routes>
          </AuthLayout>
        </BrowserRouter>
      )}
    </div>
  );
}

export default App;
