import { Col, Row } from 'antd';
import React, { useEffect } from 'react';
import { getTemplates } from '../../services/api.service';
import TemplateCard from '../../components/projects/TemplateCard/TemplateCard';
import { MODALS, useModals } from '../../context/modalContext';

export default function TemplateList() {
  const [templates, setTemplates] = React.useState([
    {
      id: 1,
      title: 'Restaurant Review platform',
      example: 'Zomato, Yelp',
      shortDescription: 'Discover, book, and review restaurants in your area or globally.',
    },
    {
      id: 2,
      title: 'E-Commerce Platform',
      shortDescription: 'Buy, sell, and manage products online, with integrated payment and inventory management.',
      example: 'Amazon, Shopify Store'
    },
    {
      id: 3,
      title: 'Online Learning Platform',
      shortDescription: 'Offer courses, track progress, and manage students and instructors.',
      example: 'Coursera, Udemy'
    },
    {
      id: 4,
      title: 'Job Portal',
      shortDescription: 'Post jobs, apply for positions, and manage job listings and applications.',
      example: 'LinkedIn, Indeed'
    },
    {
      id: 5,
      title: 'Real Estate Listing',
      shortDescription: 'List properties for sale or rent, and manage listings and inquiries.',
      example: 'Zillow, Realtor'
    },
    {
      id: 6,
      title: 'Online Booking System',
      example: 'Calendly, OpenTable',
      shortDescription: 'Schedule, book, and manage appointments for services.',
    },
    {
      id: 7,
      title: 'Fitness & Health Tracking',
      example: 'Strava, MyFitnessPal',
      shortDescription: 'Track workouts, meals, and health metrics.',
    },
    {
      id: 8,
      title: 'Content Publishing Platform',
      shortDescription: 'Write, edit, and publish articles or blogs with an integrated commenting system.',
      example: 'Medium, WordPress'
    },
    {
      id: 9,
      title: 'Financial & Budgeting App',
      shortDescription: 'Track income, expenses, and create financial goals with reporting features.',
      example: 'Mint, YNAB, Personal Capital'
    }
  ]);

  const { showModal } = useModals();

  useEffect(() => {
    getTemplates().then(({ data }) => {
      // console.log('data', data);
      // setTemplates(data);
    });
  }, []);

  const handleTemplateClick  = (template) => {
    showModal(MODALS.CREATE_PROJECT_MODAL, { selectedTemplate: template._id });
  }

  return (
    <div>
      <Row gutter={[20, 20]}>
        {templates?.map((template) => (
          <Col span={8} key={template._id}>
            <TemplateCard
              template={template}
              onClick={() => handleTemplateClick(template)}
            />
          </Col>
        ))}
      </Row>
    </div>
  );
}