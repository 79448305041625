import { Button, Card, Col, Row } from 'antd';
import React from 'react';
import './ProjectEmptyState.scss';
import { TbSend } from 'react-icons/tb';

export default function ProjectEmptyState({
  image,
  title,
  description,
  btnText,
  onClick,
}) {
  return (
    <div className="project-empty-state">
      <Card>
        <div className="main-card-empty-state-wrapper">
          <div className="img-wrapper">
            <img src={image} alt={title} />
          </div>
          <div className="content-wrapper">
            <h3>{title}</h3>
            <p className="lead mb-4">{description}</p>
            <Button
              type="primary"
              icon={<TbSend />}
              className="d-flex align-items-center"
              onClick={onClick}
            >
              {btnText}
            </Button>
          </div>
        </div>
      </Card>
    </div>
  );
}
