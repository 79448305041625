import React from 'react';
import ProjectSection from '../../components/sections/ProjectSection/ProjectSection';
import { Link } from 'react-router-dom';
import DatabaseHome from '../../containers/DatabaseHome/DatabaseHome';
import { Button, Dropdown, Space, notification } from 'antd';
import { TbCheck, TbDotsVertical, TbPlus } from 'react-icons/tb';
import { useProject } from '../../context/project';
import {
  selectActiveCollection,
  selectIfCollectionUpdated,
  selectProject,
  selectSchemaForCollection,
} from '../../context/project/selectors';
import { useMutation, useQueryClient } from 'react-query';
import { updateTableApi } from '../../services/api.service';
import {
  removeUpdatedCollectionAction,
  showModalAction,
  updateCollectionAction,
} from '../../context/project/actions';
// import { queryClient } from '../../App';

import yaml from 'js-yaml';
import Joi from 'joi';
import { PROJECT_MODALS } from '../../context/projectContext';
import { useModals } from '../../context/modalContext';

const attributeSchema = Joi.object({
  type: Joi.string()
    .valid('String', 'Boolean', 'Number', 'Date')
    .required()
    .insensitive(),
  required: Joi.boolean(),
});

const modelSchema = Joi.object({
  attributes: Joi.object().pattern(Joi.string(), attributeSchema).required(),
  routes: Joi.object().optional(),
});

const validationSchema = Joi.object().pattern(Joi.string(), modelSchema);

export default function ProjectDatabase() {
  const state = useProject();
  const queryClient = useQueryClient();
  const project = selectProject(state);
  const ctx = useModals();

  const mutation = useMutation(updateTableApi, {
    onSuccess: (data) => {
      notification.success({
        message: 'Success',
        description: 'Collection updated successfully',
      });
      queryClient.invalidateQueries('tables');
    },
  });

  const activeCollection = selectActiveCollection(state);
  const collectionSchema = selectSchemaForCollection(
    state,
    activeCollection?._id
  );
  const updated = selectIfCollectionUpdated(state);

  const updateSchemaForCollection = () => {
    const id = activeCollection._id;
    // Validate YAML Schema
    try {
      const schema = yaml.load(collectionSchema.schema);
      console.log('schema', schema);
      const validation = validationSchema.validate(schema, {
        abortEarly: false,
      });
      if (validation.error) {
        const errors = validation.error.details;
        state.dispatch(
          updateCollectionAction({
            _id: id,
            errors: errors,
          })
        );
        return;
      } else {
        console.log('Validation successful!');
        state.dispatch(
          updateCollectionAction({
            _id: id,
            errors: [],
          })
        );
      }
    } catch (error) {
      console.log('error', error);
      notification.error({
        message: 'Invalid YAML Schema!',
        description: 'Please check your YAML Schema before saving again.',
        // description: 'Invalid YAML Schema'
      });
      return;
    }

    // console.log('update schema', id, collectionSchema.schema);
    state.dispatch(removeUpdatedCollectionAction(id));
    mutation.mutate({
      projectId: project._id,
      id,
      body: {
        schema: collectionSchema.schema,
      },
    });
  };

  const items = [
    // {
    //   key: 'publish-schema',
    //   label: 'Publish Schema',
    // },
    {
      key: 'delete-collection',
      danger: true,
      label: 'Delete Collection',
    },
  ];

  const handleMenuClick = (item) => {
    console.log('item', item);
    if(item.key === 'delete-collection') {
      state.dispatch(showModalAction(PROJECT_MODALS.DELETE_COLLECTION_MODAL));
    }
  }

  const createNewTable = () => {
    state.dispatch(showModalAction(PROJECT_MODALS.CREATE_COLLECTION_MODAL));
  }

  return (
    <ProjectSection
      title={'Database Schema'}
      subTitle={activeCollection?.title}
      actions={
        <div>
          <Button
            type="default"
            icon={<TbPlus />}
            // disabled={!updated}
            // onClick={updateSchemaForCollection}
            onClick={createNewTable}
          >
            New Collection
          </Button>
          {/* <Dropdown
            menu={{
              items,
              onClick: handleMenuClick
            }}
            placement='bottomRight'
          >
              <Button className='px-3 me-2' type='text'>
                <TbDotsVertical size={20} />
              </Button>
          </Dropdown> */}
        </div>
      }
    >
      <DatabaseHome />
    </ProjectSection>
  );
}
