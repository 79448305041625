import React from 'react';
import ProjectSection from '../../components/sections/ProjectSection/ProjectSection';
import { Button, Checkbox, Empty, Form, Input, Modal, Select } from 'antd';
import { TbCheckbox, TbPlus } from 'react-icons/tb';
import ContentSection from '../../components/sections/ContentSection/ContentSection';
import RouteGroup from '../../components/ui/RouteGroup/RouteGroup';
import SingleRoute from '../../components/ui/SingleRoute/SingleRoute';
import ProjectCreateRoute from '../../components/projects/ProjectCreateRoute/ProjectCreateRoute';
import ButtonGroup from '../../components/ui/ButtonGroup/ButtonGroup';
import {
  createRouteGroupApi,
  getRouteGroupApi,
  getRouteGroupsApi,
} from '../../services/api.service';
import CreateRouteGroup from '../../modals/CreateRouteGroup/CreateRouteGroup';
import { useProject } from '../../context/project';
import { useQuery } from 'react-query';
import { useNavigate } from 'react-router-dom';
import GenerateCRUDModal from '../../modals/GenerateCRUDModal/GenerateCRUDModal';

const routesGroups = [
  {
    title: 'Authentication',
    routes: [
      {
        method: 'post',
        url: '/auth/signup',
        inputs: 2,
        outputs: 1,
        requireAuth: false,
      },
      {
        method: 'post',
        url: '/auth/login',
        inputs: 2,
        outputs: 1,
      },
      {
        method: 'post',
        url: '/auth/forget-password',
        inputs: 1,
        outputs: 0,
      },
      {
        method: 'post',
        url: '/auth/reset-password',
        inputs: 2,
        outputs: 1,
      },
    ],
  },
  {
    title: 'Countries',
    routes: [
      {
        method: 'post',
        url: '/auth/countries',
        inputs: 2,
        requireAuth: true,
      },
      {
        method: 'get',
        url: '/auth/countries',
        inputs: 0,
        outputs: 1,
      },
      {
        method: 'get',
        url: '/auth/countries/{country_id}',
        inputs: 1,
        outputs: 1,
      },
      {
        method: 'get',
        url: '/auth/countries/{country_id}/states',
        inputs: 1,
        outputs: 1,
      },
      {
        method: 'put',
        url: '/auth/countries/{country_id}',
        inputs: 2,
        outputs: 1,
      },
      {
        method: 'delete',
        url: '/auth/countries/{country_id}',
        inputs: 1,
        outputs: 0,
      },
    ],
  },
  {
    title: 'States',
    routes: [
      {
        method: 'post',
        url: '/auth/states',
        inputs: 2,
        requireAuth: true,
      },
      {
        method: 'get',
        url: '/auth/states',
        inputs: 0,
        outputs: 1,
      },
      {
        method: 'get',
        url: '/auth/states/{state_id}',
        inputs: 1,
        outputs: 1,
      },
      {
        method: 'get',
        url: '/auth/states/{state_id}/cities',
        inputs: 1,
        outputs: 1,
      },
      {
        method: 'put',
        url: '/auth/states/{state_id}',
        inputs: 2,
        outputs: 1,
      },
      {
        method: 'delete',
        url: '/auth/states/{state_id}',
        inputs: 1,
        outputs: 0,
      },
    ],
  },
  {
    title: 'City',
    routes: [
      {
        method: 'post',
        url: '/auth/cities',
        inputs: 2,
        requireAuth: true,
      },
      {
        method: 'get',
        url: '/auth/cities',
        inputs: 0,
        outputs: 1,
      },
      {
        method: 'get',
        url: '/auth/cities/{city_id}',
        inputs: 1,
        outputs: 1,
      },
      {
        method: 'put',
        url: '/auth/cities/{city_id}',
        inputs: 2,
        outputs: 1,
      },
      {
        method: 'delete',
        url: '/auth/cities/{city_id}',
        inputs: 1,
        outputs: 0,
      },
    ],
  },
  {
    title: 'Neighbourhood',
    routes: [
      {
        method: 'post',
        url: '/auth/neighbourhoods',
        inputs: 2,
        requireAuth: true,
      },
      {
        method: 'get',
        url: '/auth/neighbourhoods',
        inputs: 0,
        outputs: 1,
      },
      {
        method: 'get',
        url: '/auth/neighbourhoods/{neighbourhoods_id}',
        inputs: 1,
        outputs: 1,
      },
      {
        method: 'get',
        url: '/auth/stneighbourhoods/{neighbourhoods_id}/properties',
        inputs: 1,
        outputs: 1,
      },
      {
        method: 'put',
        url: '/auth/neighbourhoods/{neighbourhoods_id}',
        inputs: 2,
        outputs: 1,
      },
      {
        method: 'delete',
        url: '/auth/neighbourhoods/{neighbourhoods_id}',
        inputs: 1,
        outputs: 0,
      },
    ],
  },
  {
    title: 'States',
    routes: [
      {
        method: 'post',
        url: '/auth/states',
        inputs: 2,
        requireAuth: true,
      },
      {
        method: 'get',
        url: '/auth/states',
        inputs: 0,
        outputs: 1,
      },
      {
        method: 'get',
        url: '/auth/states/{state_id}',
        inputs: 1,
        outputs: 1,
      },
      {
        method: 'get',
        url: '/auth/states/{state_id}/cities',
        inputs: 1,
        outputs: 1,
      },
      {
        method: 'put',
        url: '/auth/states/{state_id}',
        inputs: 2,
        outputs: 1,
      },
      {
        method: 'delete',
        url: '/auth/states/{state_id}',
        inputs: 1,
        outputs: 0,
      },
    ],
  },
  {
    title: 'States',
    routes: [
      {
        method: 'post',
        url: '/auth/states',
        inputs: 2,
        requireAuth: true,
      },
      {
        method: 'get',
        url: '/auth/states',
        inputs: 0,
        outputs: 1,
      },
      {
        method: 'get',
        url: '/auth/states/{state_id}',
        inputs: 1,
        outputs: 1,
      },
      {
        method: 'get',
        url: '/auth/states/{state_id}/cities',
        inputs: 1,
        outputs: 1,
      },
      {
        method: 'put',
        url: '/auth/states/{state_id}',
        inputs: 2,
        outputs: 1,
      },
      {
        method: 'delete',
        url: '/auth/states/{state_id}',
        inputs: 1,
        outputs: 0,
      },
    ],
  },
  {
    title: 'States',
    routes: [
      {
        method: 'post',
        url: '/auth/states',
        inputs: 2,
        requireAuth: true,
      },
      {
        method: 'get',
        url: '/auth/states',
        inputs: 0,
        outputs: 1,
      },
      {
        method: 'get',
        url: '/auth/states/{state_id}',
        inputs: 1,
        outputs: 1,
      },
      {
        method: 'get',
        url: '/auth/states/{state_id}/cities',
        inputs: 1,
        outputs: 1,
      },
      {
        method: 'put',
        url: '/auth/states/{state_id}',
        inputs: 2,
        outputs: 1,
      },
      {
        method: 'delete',
        url: '/auth/states/{state_id}',
        inputs: 1,
        outputs: 0,
      },
    ],
  },
];

export default function ProjectRoutes() {
  const navigate = useNavigate();
  const [showCreateRouteGroup, setShowCreateRouteGroup] = React.useState(false);
  const [showCreateCrudAPIs, setShowCreateCrudAPIs] = React.useState(false);
  const { project } = useProject();
  const projectId = project?._id;
  const {
    data: routeGroups = [],
    error,
    isLoading,
  } = useQuery(['routeGroups', projectId], () => getRouteGroupsApi(projectId), {
    enabled: !!projectId,
  });
  console.log(routeGroups);

  const handleCreateRouteGroup = () => {
    setShowCreateRouteGroup(true);
  };
 
  const handleCrudAPIs = () => {
    setShowCreateCrudAPIs(true);
  };

  const handleCreateRoute = () => {
    navigate(`/projects/${projectId}/routes/new`);
  };

  const createRouteGroup = (values) => {
    console.log(values);
    setShowCreateRouteGroup(false);
    createRouteGroupApi(values).then((res) => {
      console.log(res);
    });
  };

  const routesCount = routeGroups.reduce((acc, group) => {
    return acc + group.routes.length;
  }, 0);

  return (
    <ProjectSection
      title={'Routes'}
      actions={
        <ButtonGroup>
          <Button
            type="dashed"
            className="me-2"
            icon={<TbPlus />}
            onClick={handleCrudAPIs}
          >
            Create APIs
          </Button>
          <Button
            type="dashed"
            className="me-2"
            icon={<TbPlus />}
            onClick={handleCreateRouteGroup}
          >
            Route Group
          </Button>
          <Button
            type="primary"
            className="me-2"
            icon={<TbPlus />}
            onClick={handleCreateRoute}
          >
            Route
          </Button>
        </ButtonGroup>
      }
    >
      <ContentSection>
        <h4 className="fs-5 mb-3 border-bottom pb-3">{routesCount} Active Routes</h4>
        {routeGroups.map((group, index) => (
          <RouteGroup title={group.title} key={index}>
            {group?.routes?.map((route, index) => (
              <SingleRoute
                id={route._id}
                method={route.method}
                url={route.path}
                inputs={route.inputs.length}
                functions={route.functions.length}
                auth={route.requireAuth}
              />
            ))}
            { group?.routes?.length === 0 && <div className='p-3'>
              <Empty description="No Routes for this Route Group" /></div>}
          </RouteGroup>
        ))}
      </ContentSection>
      <CreateRouteGroup
        show={showCreateRouteGroup}
        onClose={() => setShowCreateRouteGroup(false)}
      />
      <GenerateCRUDModal 
        show={showCreateCrudAPIs}
        onClose={() => setShowCreateCrudAPIs(false)}
      />
      {/* <Modal open={true} footer={false} title="Create a New Route" width={900}>
        <ProjectCreateRoute />
      </Modal> */}
    </ProjectSection>
  );
}
