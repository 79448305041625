export const ROUTE_TYPES = {
    LIST: 'list',
    GET: 'get',
    CREATE: 'create',
    UPDATE: 'update',
    DELETE: 'delete',
}

export const DEFAULT_ROUTE_CONFIG = {
    "path": "new",
    "method": "get",
    "action": {
        "inputs": [
            {
                "inputType": "body",
                "label": "name",
                "required": true,
                "hasStaticValue": false,
                "type": "String"
            },
            {
                "inputType": "query",
                "label": "name",
                "required": true,
                "hasStaticValue": false,
                "type": "String"
            },
            {
                "inputType": "params",
                "label": "name",
                "required": true,
                "hasStaticValue": false,
                "type": "String"
            }
        ],
        "functions": [
            {
                "type": "db.list",
                "filter": [
                    {
                        "field": "isActive",
                        "operator": "eq",
                        "value": true
                    },
                    {
                        "field": "date",
                        "operator": "gte",
                        "value": "Inputs.date"
                    }
                ]
            }
        ]
    }
}