// import {
//   deployProjectApi,
//   getProjectApi,
//   updateProjectApi,
// } from '../services/api.service';
// import { notification } from 'antd';
// import { createContext, useState, useContext, useEffect } from 'react';
// import { useParams } from 'react-router-dom';

export const PROJECT_MODALS = {
  ENABLE_AUTHENTICATION_MODAL: 'showEnableAuthenticationModal',
  DEPLOY_MODAL: 'showDeployModal',
  CREATE_COLLECTION_MODAL: 'showCreateCollectionModal',
  DELETE_COLLECTION_MODAL: 'showDeleteCollectionModal',
};

// const ProjectContext = createContext();

// export const ProjectProvider = ({ children }) => {
//   const [project, setProject] = useState(null);
//   const [saved, setSaved] = useState(null);
//   const [schema, setSchema] = useState(null);
//   const [modals, setModals] = useState({
//     showEnableAuthenticationModal: false,
//     showDeployModal: false,
//     showCreateCollectionModal: false,
//   });
//   const { project: projectId } = useParams();

//   const fetchProject = (projectId) => {
//     if (projectId) {
//       getProjectApi(projectId).then(({ data }) => {
//         setProject(data);
//         setSchema(data.schema);
//       });
//     }
//   };

//   const saveProject = (project) => {
//     setProject(project);
//     setSchema(project.schema);
//   };

//   const saveSchema = () => {
//     updateProjectApi(project._id, { schema }).then(({ data }) => {
//       console.log(data);
//       notification.success({
//         message: 'Schema saved',
//         description: 'Your schema has been saved',
//       });
//     });
//   };

//   const showModal = (modal) => {
//     console.log(modal);
//     setModals({
//       ...modals,
//       [modal]: true,
//     });
//   };

//   const hideModal = (modal) => {
//     setModals({
//       ...modals,
//       [modal]: false,
//     });
//   };

//   return (
//     <ProjectContext.Provider
//       value={{
//         project,
//         setProject,
//         saved,
//         setSaved,
//         schema,
//         setSchema,
//         saveSchema,
//         showModal,
//         hideModal,
//         modals,
//         fetchProject,
//         saveProject,
//       }}
//     >
//       {children}
//     </ProjectContext.Provider>
//   );
// };

// export const useProject = () => useContext(ProjectContext);
