import React from 'react';
import DashboardHeader from '../../components/sections/DashboardHeader/DashboardHeader';
import UIButton from '../../components/ui/UIButton/UIButton';
import { Space } from 'antd';
import { TbPlus } from 'react-icons/tb';
import PageContent from '../../components/sections/PageContent/PageContent';
import { MODALS, useModals } from '../../context/modalContext';

export default function ProjectLayout({ title, heading, children }) {

  const { showModal } = useModals();

  const handleAddProjectClick = () => {
    console.log('Add project clicked');
    showModal(MODALS.SELECT_NEW_PROJECT_TYPE);
  };

  return (
    <div className="dashboard-page">
      <DashboardHeader
        title={title}
        heading={heading}
        links={[
          {
            title: 'Projects',
            href: '/projects',
          },
          {
            title: 'Templates',
            href: '/templates',
          },
        ]}
        actions={
          <Space size={10}>
            <UIButton
              title={'Pick a Template'}
              link={'/templates'}
              icon={<TbPlus />}
            />
            <UIButton
              title={'New Project'}
              theme="primary"
              onClick={handleAddProjectClick}
            />
          </Space>
        }
      />
      <PageContent>
        <div className="container">{children}</div>
      </PageContent>
    </div>
  );
}
