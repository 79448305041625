import { Table } from 'antd'
import React from 'react'

export default function ProjectPaymentList() {
  return (
    <Table>
        <Table.Column title="Order Id." dataIndex="orderId" key="orderId" />
        <Table.Column title="Name" dataIndex="name" key="name" />
        <Table.Column title="Email" dataIndex="email" key="email" />
        <Table.Column title="Amount" dataIndex="name" key="name" />
        <Table.Column title="Payment Date" dataIndex="name" key="name" />
        <Table.Column title="Payment Status" dataIndex="name" key="name" />
    </Table>
  )
}
