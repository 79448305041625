import React, { useCallback, useEffect } from 'react';
import DashboardHeader from '../../components/sections/DashboardHeader/DashboardHeader';
import UIButton from '../../components/ui/UIButton/UIButton';
import {
  TbCheck,
  TbClipboardText,
  TbCloudDownload,
  TbDatabase,
  TbDiscount,
  TbEdit,
  TbExternalLink,
  TbFolder,
  TbHierarchy2,
  TbHierarchy3,
  TbHome,
  TbHome2,
  TbHomeBolt,
  TbLayersLinked,
  TbLifebuoy,
  TbLink,
  TbLock,
  TbPencil,
  TbPremiumRights,
  TbSchema,
  TbSettings,
  TbUpload,
  TbUserCode,
} from 'react-icons/tb';
import { Button, Space } from 'antd';
import { PROJECT_MODALS } from '../../context/projectContext';
import { Link, NavLink, Outlet, useParams } from 'react-router-dom';
import { getProjectApi } from '../../services/api.service';
import DeployProjectModal from '../../modals/DeployProjectModal';
import EnableAuthenticationModal from '../../modals/EnableAuthenticationModal';
import CreateCollectionModal from '../../modals/CreateCollectionModal';
import { ProjectProvider, useProject } from '../../context/project';
import { checkIfProjectIsDeployed, checkIfProjectIsSaved, selectProject, selectProjectDeploying } from '../../context/project/selectors';
// import { fetchProjectEffect } from '../../context/project/effects';
import { showModalAction, updateDeployingStatus } from '../../context/project/actions';
import DeleteCollectionModal from '../../modals/DeleteCollectionModal';
import './ProjectHomeLayout.scss';
import UpdateCollectionModal from '../../modals/UpdateCollectionModal/UpdateCollectionModal';

const projectNavigation = [
  {
    title: 'Dashboard',
    icon: <TbHome />,
    path: '/projects/:project',
    end: true,
  },
  {
    title: 'Schema',
    icon: <TbHierarchy2 />,
    path: '/projects/:project/database',
  },
  {
    title: 'Database CMS',
    icon: <TbDatabase />,
    path: '/projects/:project/data',
    end: false,
  },
  {
    title: 'Authentication',
    icon: <TbLock />,
    path: '/projects/:project/authentication',
  },
  // {
  //   title: 'Payments',
  //   icon: <TbPremiumRights />,
  //   path: '/projects/:project/payments',
  // },
  {
    title: 'Routes & APIs',
    icon: <TbLayersLinked />,
    path: '/projects/:project/routes',
  },
  // {
  //   title: 'Storage',
  //   icon: <TbFolder />,
  //   path: '/projects/:project/storage',
  // },
  {
    title: 'Documentation',
    icon: <TbClipboardText />,
    path: '/projects/:project/api-docs',
  },
  // {
  //   title: 'Export',
  //   icon: <TbCloudDownload />,
  //   path: '/projects/:project/export',
  // },
  {
    title: 'Settings',
    icon: <TbSettings />,
    path: '/projects/:project/settings',
  },
];

function ProjectHomeLayoutWrapper({ children }) {
  const { project: projectSlug } = useParams();
  const state = useProject();
  const project = selectProject(state);
  const deployed = checkIfProjectIsDeployed(state);
  // const [deploying, setDeploying] = React.useState(false);
  const deploying = selectProjectDeploying(state);

    // console.log('state', state);
  const { saveSchema, showModal, setProject, fetchProject } = state;

  const handleDeployProject = () => {
    state.dispatch(showModalAction(PROJECT_MODALS.DEPLOY_MODAL));
    // showModal(PROJECT_MODALS.DEPLOY_MODAL, { selectedProject: project._id });
  };

  const openDocsLink = () => {
    window.open(project.url + '/api-docs');
  };

  const handleSaveSchema = () => {
    saveSchema();
  };

  const handleStateChange = (pState) => {
    state.dispatch(updateDeployingStatus(pState !== "deployed"));
    // setDeploying(state !== "deployed");
  }

  // useEffect(() => {
  //   console.log('projectSlug', projectSlug);
  //   if (projectSlug && state.dispatch) {
  //     fetchProjectEffect(state.dispatch, projectSlug);
  //   }
  // }, [projectSlug, state.dispatch]);

  const activeClassFunc = ({ isActive, isPending }) =>
    isPending ? 'pending' : isActive ? 'active' : '';

  return (
    <div className="dashboard-page project-page">
      <DashboardHeader
        title={'Projects: ' + project?.title}
        deploying={deploying}
        actions={
          <Space size={15}>
            <Button type="link" onClick={openDocsLink} className='docs-link-btn'>
              <TbExternalLink size={18} />
              <span>View API Docs</span>
            </Button>
            {/* <UIButton
              title={'Save'}
              theme="default"
              icon={<TbCheck />}
              // disabled={!(saved === false)}
              onClick={handleSaveSchema}
            /> */}
            <UIButton
              title={'Deploy Changes'}
              theme="primary"
              icon={<TbUpload />}
              onClick={handleDeployProject}
              disabled={deployed}
            />
          </Space>
        }
      />
      <div>
        <div className="project-edit-page-main">
          <div className="project-edit-sidebar">
            <div className="project-edit-sidebar-nav">
              <ul>
                {projectNavigation.map((item, index) => (
                  <li key={index}>
                    <NavLink
                      to={item.path.replace(':project', projectSlug)}
                      className={activeClassFunc}
                      end={item.end}
                    >
                      {item.icon}
                      <span>{item.title}</span>
                    </NavLink>
                  </li>
                ))}
              </ul>
            </div>
          </div>
          <div className="project-main-content">
            <Outlet />
          </div>
        </div>
      </div>
      <div>
        <DeployProjectModal onStateChange={handleStateChange} />
        <EnableAuthenticationModal />
        <CreateCollectionModal />
        <DeleteCollectionModal />
        <UpdateCollectionModal />
      </div>
    </div>
  );
}

export default function ProjectHomeLayout ({ children }) {
  return (
    <ProjectProvider>
      <ProjectHomeLayoutWrapper />
    </ProjectProvider>
  )
}