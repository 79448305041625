import axios from './axios';

export const loginApi = (body) => {
  return axios.post('/login', body).then((response) => {
    return response.data;
  });
};

export const signupApi = (body) => {
  return axios.post('/signup', body).then((response) => {
    return response.data;
  });
};

export const forgotPasswordApi = async (body) => {
  return axios.post('/forget-password', body).then((response) => {
    return response.data;
  });
};

export const resetPasswordApi = async (body) => {
  return axios.post('/reset-password', body).then((response) => {
    return response.data;
  });
};

export const updateAccount = async (body) => {
  return axios.put('/update-account', body).then((response) => {
    return response.data;
  });
};

// export const getOrganizationsApi = async (body) => {
//   return axios.get('/organizations').then((response) => {
//     return response.data;
//   });
// };

export const updatePassword = async (body) => {
  return axios.put('/update-password', body).then((response) => {
    return response.data;
  });
};

export const getProjectsApi = (body) => {
  return axios.get('/projects').then((response) => {
    return response.data;
  });
};

export const getDashboardStats = () => {
  return axios.get('/projects/dashboard').then((response) => {
    return response.data;
  });
};

export const getOrganizationsApi = () => {
  return axios.get('/organizations').then((response) => {
    return response.data;
  });
};

export const createProjectApi = (body) => {
  return axios.post('/projects', body).then((response) => {
    return response.data;
  });
};

export const getProjectApi = (id) => {
  return axios.get(`/projects/${id}`).then((response) => {
    return response.data;
  });
};

export const updateProjectApi = (id, body) => {
  return axios.put(`/projects/${id}`, body).then((response) => {
    return response.data;
  });
};

export const deleteProjectApi = (id, body) => {
  return axios.delete(`/projects/${id}`, body).then((response) => {
    return response.data;
  });
};

export const deployProjectApi = (id) => {
  return axios.put(`/projects/${id}/deploy`).then((response) => {
    return response.data;
  });
};

export const getAuthenticatedUsers = (id) => {
  return axios.get(`/projects/${id}/authusers`).then(({ data: res }) => {
    return res.data;
  });
};

export const deleteAuthenticatedUser = (id, userId) => {
  return axios.delete(`/projects/${id}/authusers/${userId}`).then(({ data: res }) => {
    return res.data;
  });
};

export const enableModuleApi = (id, module) => {
  return axios
    .put(`/projects/${id}/enable-module`, {
      module,
    })
    .then((response) => {
      return response.data;
    });
};

export const getTemplates = () => {
  return axios.get(`/templates`).then((response) => {
    return response.data;
  });
};

export const getSingleTemplates = (id) => {
  return axios.get(`/templates/` + id).then((response) => {
    return response.data;
  });
};

export const getTables = (projectId) => {
  console.log('projectId', projectId);
  return axios.get(`/projects/${projectId}/tables`).then(({ data: res }) => {
    return res.data;
  });
};

export const getSingleTable = (projectId, id) => {
  return axios
    .get(`/projects/${projectId}/tables/` + id)
    .then(({ data: response }) => {
      return response.data;
    });
};

export const createTableApi = ({ projectId, body }) => {
  return axios
    .post(`/projects/${projectId}/tables`, body)
    .then(({ data: response }) => {
      return response.data;
    });
};

export const updateTableApi = ({ projectId, id, body }) => {
  return axios
    .put(`/projects/${projectId}/tables/${id}`, body)
    .then(({ data: res }) => {
      return res.data;
    });
};

export const deleteTableApi = (projectId, id) => {
  return axios
    .delete(`/projects/${projectId}/tables/${id}`)
    .then((response) => {
      return response.data;
    });
};

export const getSingleTableData = (projectId, id) => {
  return axios
    .get(`/projects/${projectId}/tables/` + id + '/data')
    .then(({ data: response }) => {
      return response.data;
    });
};

export const createSingleTableData = (projectId, tableId, values) => {
  return axios
    .post(`/projects/${projectId}/tables/` + tableId + '/data', values)
    .then(({ data: response }) => {
      return response.data;
    });
};

export const updateSingleTableData = (projectId, tableId, recordId, update) => {
  return axios
    .put(`/projects/${projectId}/tables/${tableId}/data/${recordId}`, update)
    .then(({ data: response }) => {
      return response.data;
    });
};

export const deleteSingleTableData = (projectId, id, recordId) => {
  return axios
    .delete(`/projects/${projectId}/tables/${id}/data/${recordId}`)
    .then(({ data: response }) => {
      return response.data;
    });
};

export const createRouteGroupApi = ({ projectId, body }) => {
  return axios
    .post(`/projects/${projectId}/route-groups`, body)
    .then((response) => {
      return response.data;
    });
};

export const getRouteGroupsApi = (projectId) => {
  return axios
    .get(`/projects/${projectId}/route-groups`)
    .then(({ data: res }) => {
      return res.data;
    });
};

export const createRouteCRUD = ({ projectId, table, createRouteGroup }) => {
  return axios
    .post(`/projects/${projectId}/routes/create-crud`, {
      table,
      createRouteGroup,
    })
    .then((response) => {
      return response.data;
    });
};

export const createRouteApi = ({ projectId, body }) => {
  return axios
    .post(`/projects/${projectId}/routes`, body)
    .then((response) => {
      return response.data;
    });
};

export const getRoutesApi = (projectId) => {
  return axios
    .get(`/projects/${projectId}/routes`)
    .then(({ data: res }) => {
      return res.data;
    });
};

export const getSingleRouteApi = (projectId, routeId) => {
  return axios
    .get(`/projects/${projectId}/routes/${routeId}`)
    .then(({ data: res }) => {
      return res.data;
    });
};

export const updateSingleRouteApi = (projectId, routeId, body) => {
  return axios
    .put(`/projects/${projectId}/routes/${routeId}`, body)
    .then(({ data: res }) => {
      return res.data;
    });
};

// Github API
export const getGithubOrganizationsApi = (projectId) => {
  return axios.get(`/projects/${projectId}/github/organizations`).then(({ data: res }) => {
    return res.data;
  });
}

export const createAndPublishRepoApi = (projectId) => {
  return axios.post(`/projects/${projectId}/github/repository`).then(({ data: res }) => {
    return res.data;
  });
}