import React from 'react';
import './RouteGroup.scss';
import { TbCirclePlus } from 'react-icons/tb';

export default function RouteGroup({ title, children }) {
  return (
    <div className="route-group">
      <div className="route-group__title">
        <h3>{title}</h3>
        <div className="route-group__actions">
            <button><TbCirclePlus /> New Route</button>
        </div>
      </div>
      <div className="route-group__routes">{children}</div>
    </div>
  );
}
