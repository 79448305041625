import React, { useEffect } from 'react';
import { deleteAuthenticatedUser, getAuthenticatedUsers } from '../../services/api.service';
import { Alert, Button, Modal, Space, Table } from 'antd';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { TbTrash } from 'react-icons/tb';

export default function AuthenticatedUsersList({ project }) {
  const { data: users } = useQuery('authenticatedUsers', () =>
    getAuthenticatedUsers(project._id)
  );
  const queryClient = useQueryClient();
  const mutation = useMutation((userId) => deleteAuthenticatedUser(project._id, userId), {
    onSuccess: () => {
      queryClient.invalidateQueries('authenticatedUsers');
    }
  });

  const [showDeleteModal, setShowDeleteModal] = React.useState({
    show: false,
    record: null,
  });

  const prepareForDelete = (user) => {
    setShowDeleteModal({ show: true, record: user });
  };

  const deleteUser = () => {
    console.log('delete user');
    mutation.mutate(showDeleteModal.record._id);
    hideModal();
  };

  const hideModal = () => {
    setShowDeleteModal({ show: false, record: null });
  };

  return (
    <>
      <Table dataSource={users} key={'_id'} rowKey={'_id'}>
        <Table.Column
          title="First Name"
          dataIndex="firstName"
          key="firstName"
        />
        <Table.Column title="Last Name" dataIndex="lastName" key="lastName" />
        <Table.Column title="Email" dataIndex="email" key="email" />
        <Table.Column title="Phone" dataIndex="phone" key="phone" />
        <Table.Column
          title="Actions"
          key={'actions'}
          dataIndex={'actions'}
          render={(_, record) => (
            <Space size="middle">
              <Button
                icon={<TbTrash />}
                onClick={() => prepareForDelete(record)}
              ></Button>
            </Space>
          )}
        />
      </Table>
      <Modal
        open={showDeleteModal.show}
        title="Delete User?"
        okText="Yes Delete!"
        onOk={deleteUser}
        onCancel={hideModal}
      >
        <p>
          This action is irreversible and will delete the user from the project.
        </p>
        <Alert
          type="error"
          message={`Deleting User: ${showDeleteModal?.record?.firstName}`}
          className="mb-2 mt-2"
        />
      </Modal>
    </>
  );
}
