import React from 'react'
import { useNavigate, useParams } from "react-router-dom";
import { resetPasswordApi } from '../../services/api.service';
import { Form, Input, Button, notification } from 'antd';

export default function ResetPassword() {
  const { token } = useParams();

  const handleSubmit = ({ password }) => {
    resetPasswordApi({ token, password }).then(({ data }) => {
      window.location.href = '/login?reset=true';
    }).catch((err) => {
      notification.error({
        message: 'Email not match',
        description: err.message
      });
    });
  };
  return (
    <div className="form-wrapper auth-form pt-5 p-3">
      <Form layout="vertical" onFinish={handleSubmit}>
        <div className="head mb-3">
          <h3 className="text-white mb-4">Reset your password?</h3>
          <p className="text-white fs-5 mb-0">Please enter the new password to rest your account password.</p>
        </div>

        <Form.Item name={'password'} label="Password">
          <Input placeholder="*******" type="password" />
        </Form.Item>
        <Form.Item name={'confirmPassword'} label="Confirm Password">
          <Input placeholder="*******" type="password" />
        </Form.Item>
        <Button block size="large" htmlType='submit'>
          Reset Password
        </Button>
      </Form>
    </div>
  )
}
