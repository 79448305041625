import { Alert, Modal } from 'antd';
import React from 'react';
import { useProject } from '../context/project';
import UIButton from '../components/ui/UIButton/UIButton';
import { TbRecycle, TbTrash } from 'react-icons/tb';
import { selectActiveCollection } from '../context/project/selectors';
import { useMutation, useQueryClient } from 'react-query';
import { deleteTableApi } from '../services/api.service';
import { useNavigate } from 'react-router-dom';
import { hideModalAction } from '../context/project/actions';
import { PROJECT_MODALS } from '../context/projectContext';

export default function DeleteCollectionModal() {
  const state = useProject();
  const navigate = useNavigate();
  const { showDeleteCollectionModal } = state.modals;
  const activeCollection = selectActiveCollection(state);
  const queryClient = useQueryClient();

  const mutation = useMutation(deleteTableApi, {
    onSuccess: () => {
      queryClient.invalidateQueries('tables');
      navigate('/projects/' + state.project._id + '/database');
    }
  })

  const handleDeleteCollection = () => {
    mutation.mutate(activeCollection._id);
    hideModal();
  }

  const hideModal = () => {
    state.dispatch(hideModalAction(PROJECT_MODALS.DELETE_COLLECTION_MODAL));
  }

  return (
    <Modal open={showDeleteCollectionModal} footer={false} onCancel={hideModal}>
      <p className="p-3">
        <h4 className="fs-5">Delete Collection</h4>
        <p>
          The collection will be deleted from the database. This action cannot
          be undone.
        </p>
        <Alert
          description={`Deleting Collection: ${activeCollection?.title}`}
          showIcon
          type="error"
        />
        <div className="mt-4"></div>
        <UIButton
          theme="primary"
          title={'Delete Collection'}
          icon={<TbTrash />}
          onClick={handleDeleteCollection}
        />
      </p>
    </Modal>
  );
}
