import React, { useCallback, useEffect } from 'react';
import { Handle, Position } from 'reactflow';
import './CollectionTableNode.scss';
import {
  TbCheck,
  TbCirclePlus,
  TbEdit,
  TbPlus,
  TbRecycle,
  TbTrash,
} from 'react-icons/tb';
import { Button, Form, Input, Modal, Tooltip } from 'antd';
import SchemaEditor from '../../../../containers/SchemaEditor/SchemaEditor';
import UIButton from '../../../ui/UIButton/UIButton';
import CollectionTableForm from '../CollectionTableForm/CollectionTableForm';
import { convertSchemaObjectToSchemaArray } from '../../../../utils';
const yaml = require('js-yaml');

export default function CollectionTableNode({ data }) {
  const { title } = data;
  const [editSchemaModal, setEditSchemaModal] = React.useState(false);
  const [schema, setSchema] = React.useState('');
  const [schemaValues, setSchemaValues] = React.useState([]);
  const [valid, setValid] = React.useState(false);

  const handleSchemaUpdate = useCallback((localSchema) => {
    console.log('schema', localSchema);
    setSchema(localSchema);
    try {
      const doc = yaml.load(localSchema);
      setValid(true);
    } catch (error) {
      setValid(false);
    }
    // console.log('values', values);
  }, []);

  console.log('data', data);

  // id: 0,
  // title: 'id',
  // type: 'String',
  // primaryKey: true,
  const attributes = convertSchemaObjectToSchemaArray(data.schemaJson);

  const handleFinalSchema = () => {
    console.log('schema', schema);
    const doc = yaml.load(schema);
    console.log('doc', doc);
    const values = Object.values(doc)[0];
    console.log('values', values);
    const valuesArray = Object.keys(values).map((value) => {
      return {
        title: value,
        type: values[value].type,
      };
    });
    console.log('valuesArray', valuesArray);
    setSchemaValues(valuesArray);
    setEditSchemaModal(false);
  };

  return (
    <>
      <div className="node-collection">
        <div className="node-header">
          <div className="node-title">{title}</div>
          <div className="node-actions">
            <Tooltip title="Edit Schema" onClick={setEditSchemaModal}>
              <button>
                <TbEdit />
              </button>
            </Tooltip>
            {/* <Tooltip title="Add Attribute">
              <button><TbCirclePlus /></button>
            </Tooltip> */}
            <Tooltip title="Delete Schema">
              <button>
                <TbTrash />
              </button>
            </Tooltip>
          </div>
        </div>
        <div className="node-params">
          {attributes.map((attribute, index) => (
            <div className="single-node-param" key={index}>
              {/* <Handle type="target" position={Position.Left} /> */}
              { attribute.primaryKey && <Handle type="source" position={Position.Left} id={`source_${title}_${attribute.title}`} /> }
              <div className="param-name">{attribute.title}</div>
              <div className="param-type">
                {attribute.type}
                {attribute.primaryKey && <span>(PK)</span>}
                {attribute.foreignKey && <span>(FK)</span>}
              </div>
              { attribute.foreignKey && <Handle type="target" position={Position.Right} id={`target_${title}_${attribute.title}`} /> }
            </div>
          ))}
          {attributes.length === 0 && (
            <div className="no-attribute-param">
              <div className="param-name">No attributes</div>
              {/* <button className='btn btn-text btn-small'><TbPlus /> Create Attribute</button> */}
              {/* <Button type='text' className='px-0' size='small' icon={<TbPlus />}>Create Attribute</Button> */}
            </div>
          )}
        </div>
        <Modal
          open={editSchemaModal}
          title={'Update Schema'}
          footer={false}
          width={900}
          onCancel={() => setEditSchemaModal(false)}
        >
          {/* <div className="schema-editor-wrapper">
            <SchemaEditor onChange={handleSchemaUpdate} />
          </div> */}
          <div className="schema-editor-form">
            <CollectionTableForm collection={data} onClose={() => setEditSchemaModal(false)} />
          </div>
        </Modal>
      </div>
    </>
  );
}
