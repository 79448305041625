import React from 'react';
import AceEditor from 'react-ace';

import 'ace-builds/src-noconflict/mode-java';
import 'ace-builds/src-noconflict/theme-monokai';
import 'ace-builds/src-noconflict/ext-language_tools';

export default function AceEditorWrapper({ initialValue, handleChange }) {
  function onChange(newValue) {
    console.log('change', newValue);
    handleChange(newValue);
  }

  return (
    <AceEditor
      defaultValue={initialValue}
      mode="java"
      theme="monokai"
      onChange={onChange}
      name="UNIQUE_ID_OF_DIV"
      editorProps={{ $blockScrolling: true }}
      highlightActiveLine={true}
      width="100%"
      tabSize={2}
    />
  );
}
