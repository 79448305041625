import React from 'react';
import SettingsLayout from './SettingsLayout';
import { Button, Form, Input } from 'antd';
import { useAuth } from '../../context/authContext';
import SettingsPage from './SettingPage';
import { updateAccount } from '../../services/api.service';
import UIButton from '../../components/ui/UIButton/UIButton';
import { TbCheck } from 'react-icons/tb';

export default function AccountSettings() {
  const { user, updateUser } = useAuth();
  const [form] = Form.useForm();

  const handleFormSubmit = (values) => {
    updateAccount(values).then(({ data }) => {
      console.log(data);
      updateUser(data);
    });
  };

  return (
    <SettingsPage title={'Manage Your Account'}>
      <Form
        layout="vertical"
        form={form}
        onFinish={handleFormSubmit}
        initialValues={{
          name: user?.name,
          email: user?.email,
        }}
      >
        <Form.Item name={'name'} label="Name">
          <Input size="large" defaultValue={user?.name} />
        </Form.Item>
        <Form.Item name={'email'} label="Email">
          <Input size="large" disabled />
        </Form.Item>
        <Form.Item>
          <UIButton type="submit" title={'Update Account'} icon={<TbCheck />} />
        </Form.Item>
      </Form>
    </SettingsPage>
  );
}
