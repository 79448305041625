import { Button, Form, Input, Modal, message } from 'antd';
import React from 'react';
import { useProject } from '../../context/project';
import { createRouteGroupApi } from '../../services/api.service';
import { useMutation, useQueryClient } from 'react-query';

export default function CreateRouteGroup({ show, onClose }) {
  const { project } = useProject();
  const queryClient = useQueryClient();

  const mutation = useMutation(createRouteGroupApi, {
    onSuccess: (data) => {
      queryClient.invalidateQueries('routeGroups');
      message.open({
        type: 'success',
        content: 'Collection updated successfully',
        duration: 5,
      });
    },
  });

  const createRouteGroup = (values) => {
    console.log(values);
    mutation.mutate({
      projectId: project._id,
      body: values
    });
    onClose();
  };

  return (
    <Modal
      open={show}
      footer={false}
      title="Create a New Route Group"
      onCancel={onClose}
    >
      <Form layout="vertical" onFinish={createRouteGroup}>
        <Form.Item label="Route Group Name" name={'title'}>
          <Input type="text" className="form-control" />
        </Form.Item>
        <Form.Item label="Route Group Description" name={'shortDescription'}>
          <Input.TextArea className="form-control" rows="3"></Input.TextArea>
        </Form.Item>
        <Form.Item>
          <Button htmlType="submit" type="primary">
            Create Route Group
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  );
}
