import React from 'react';
import ProjectSection from '../../components/sections/ProjectSection/ProjectSection';
import { Button, Empty, Tag } from 'antd';
import { PROJECT_MODALS } from '../../context/projectContext';
import { TbCheck, TbCheckbox } from 'react-icons/tb';
import AuthenticatedUsersList from './AuthenticatedUsersList';
import ContentSection from '../../components/sections/ContentSection/ContentSection';
import { useProject } from '../../context/project';
import { checkIfModuleIsEnabled, selectProject } from '../../context/project/selectors';
import { showModalAction } from '../../context/project/actions';
import ProjectEmptyState from '../../components/projects/ProjectEmptyState/ProjectEmptyState';


const AuthenticationButton = ({ project, onClick }) => {
  return checkIfModuleIsEnabled(project) ? (
    <Tag className='px-5 py-4 d-block'>Enabled</Tag>
  ) : (
    <Button icon={<TbCheck />} onClick={onClick}>
      Enable
    </Button>
  );
  // <Button>Enable</Button>
};

export default function ProjectAuthentication() {
  const state = useProject();
  const project = selectProject(state);
  const isAuthEnabled = checkIfModuleIsEnabled(state, 'authentication');

  const handleShow = () => {
    state.dispatch(showModalAction(PROJECT_MODALS.ENABLE_AUTHENTICATION_MODAL));
  };

  return (
    <ProjectSection
      title={'Authentication'}
      actions={
        isAuthEnabled ? (
          <Tag className='px-3 py-1' color='green'>Enabled</Tag>
        ) : (
          <Button
            type="primary"
            icon={<TbCheckbox />}
            className="d-inline-flex align-items-center"
            onClick={() => handleShow(true)}
          >
            Enable Authentication
          </Button>
        )
      }
    >
      <ContentSection>
        {isAuthEnabled ? (
          <>{project && <AuthenticatedUsersList project={project} />}</>
        ) : (
          <ProjectEmptyState
            image={'/images/authentication.png'}
            title={'Secure Your Backend Application with Authentication'}
            description={
              'With just a clicks, activate the NodeCompose Authentication Module, ensuring every user interaction with your application is verified and safeguarded.'
            }
            btnText={'Enable Authentication'}
            onClick={handleShow}
          />
        )}
      </ContentSection>
    </ProjectSection>
  );
}
