import React from 'react';
import { Link, NavLink, Outlet } from 'react-router-dom';
import DashboardHeader from '../../components/sections/DashboardHeader/DashboardHeader';
import PageContent from '../../components/sections/PageContent/PageContent';
import './Settings.scss';

export default function SettingsLayout({ children }) {
  const activeClassFunc = ({ isActive, isPending }) =>
    isPending ? 'pending' : isActive ? 'active' : '';

  return (
    <div className="dashboard-page settings-page">
      <DashboardHeader title={'Settings'} />
      <PageContent>
        <div className="container">
          <div className="row">
            <div className="col-md-3" span={6}>
              <div className="setting-navigation">
                <h4 className="setting-heading">Settings</h4>
                <ul>
                  <li>
                    <NavLink to="/settings/account" className={activeClassFunc}>
                      Account
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      to="/settings/password"
                      className={activeClassFunc}
                    >
                      Password
                    </NavLink>
                  </li>
                  {/* <li>
                    <NavLink to="/settings/plan" className={activeClassFunc}>
                      Billing
                    </NavLink>
                  </li> */}
                  {/* <li>
                    <NavLink
                      to="/settings/organization"
                      className={activeClassFunc}
                    >
                      Organization
                    </NavLink>
                  </li>
                  <li>
                    <NavLink to="/settings/delete" className={activeClassFunc}>
                      Delete Account
                    </NavLink>
                  </li> */}
                </ul>
              </div>
            </div>
            <div className="col-md-9" span={18}>
              <Outlet />
            </div>
          </div>
        </div>
      </PageContent>
    </div>
  );
}
