import React, { useCallback, useEffect, useMemo } from 'react';
import ReactFlow, {
  Background,
  Controls,
  MiniMap,
  NodeToolbar,
  Panel,
  addEdge,
  useEdgesState,
  useNodesState,
} from 'reactflow';
import { Handle, Position } from 'reactflow';

import 'reactflow/dist/style.css';
import CollectionTableNode from './CollectionTableNode/CollectionTableNode';
import {
  categorySchema,
  citySchema,
  countriesSchema,
  hostSchema,
  neighbourhoodSchema,
  neighbourhoodTypeSchema,
  propertySchema,
  reviewSchema,
  stateSchema,
} from './schema';
import './ProjectSchemaBuilder.scss';
import { useMutation } from 'react-query';
import { updateTableApi } from '../../../services/api.service';
import { message, notification } from 'antd';

const defaultInitialNodes = [
  {
    id: '1',
    type: 'textUpdater',
    position: { x: 100, y: 100 },
    data: countriesSchema,
  },
  {
    id: '2',
    type: 'textUpdater',
    position: { x: 400, y: 100 },
    data: stateSchema,
  },
  {
    id: '3',
    type: 'textUpdater',
    position: { x: 700, y: 90 },
    data: citySchema,
  },
  {
    id: '4',
    type: 'textUpdater',
    position: { x: 1300, y: 100 },
    data: categorySchema,
  },
  {
    id: '5',
    type: 'textUpdater',
    position: { x: 100, y: 400 },
    data: neighbourhoodTypeSchema,
  },
  {
    id: '6',
    type: 'textUpdater',
    position: { x: 400, y: 400 },
    data: neighbourhoodSchema,
  },
  {
    id: '7',
    type: 'textUpdater',
    position: { x: 1000, y: 100 },
    data: propertySchema,
  },
  {
    id: '8',
    type: 'textUpdater',
    position: { x: 700, y: 400 },
    data: hostSchema,
  },
  {
    id: '9',
    type: 'textUpdater',
    position: { x: 1300, y: 450 },
    data: reviewSchema,
  },
];
const defaultInitialEdges = [
  {
    id: 'e1-1',
    source: '1',
    target: '2',
    type: 'smoothstep',
    style: { stroke: '#5c2ab5' },
  },
  {
    id: 'e1-2',
    source: '2',
    target: '3',
    type: 'smoothstep',
    style: { stroke: '#5c2ab5' },
  },
  {
    id: 'e1-3',
    source: '5',
    target: '6',
    type: 'smoothstep',
    style: { stroke: '#5c2ab5' },
  },
  {
    id: 'e1-5',
    source: '3',
    target: '6',
    sourceHandle: 'source_City_id',
    targetHandle: 'target_Neighbourhood_cityId',
    type: 'smoothstep',
    style: { stroke: '#5c2ab5' },
  },
  {
    id: 'e1-5',
    source: '3',
    target: '7',
    sourceHandle: 'source_City_id',
    targetHandle: 'target_Properties_cityId',
    type: 'smoothstep',
    style: { stroke: '#5c2ab5' },
  },
  {
    id: 'e1-5',
    source: '6',
    target: '7',
    sourceHandle: 'source_Neighbourhood_id',
    targetHandle: 'target_Properties_neighbourhoodId',
    type: 'smoothstep',
    style: { stroke: '#5c2ab5' },
  },
];

const WIDTH = 250;
const GAP = 40;

export default function ProjectSchemaBuilder({ collections = [] }) {
  const initialNodes = [];
  const initialEdges = [];

  const prepareNodes = useCallback(() => {
    const nodes = [];
    collections.forEach((collection, index) => {
      const position = collection.position || { x: GAP + (index * (WIDTH + GAP)), y: GAP };
      const node = {
        id: collection._id,
        type: 'textUpdater',
        position: position,
        data: collection,
      };
      nodes.push(node);
    });
    return nodes;
  }, [collections]);

  const mutation = useMutation(updateTableApi, {
    onSuccess: (data) => {
      message.open({
        type: 'success',
        content: 'Collection updated successfully',
        duration: 5,
      });
      // queryClient.invalidateQueries('tables');
    },
  });

  const [nodes, setNodes, onNodesChange] = useNodesState(initialNodes);
  const [edges, setEdges, onEdgesChange] = useEdgesState(initialEdges);

  const nodeTypes = useMemo(() => ({ textUpdater: CollectionTableNode }), []);

  const onConnect = useCallback(
    (params) => setEdges((eds) => addEdge(params, eds)),
    [setEdges]
  );

  console.log('collections', collections);

  useEffect(() => {
    if(collections.length) {
      const newNodes = prepareNodes();
      setNodes(newNodes);
    }
  }, [collections, prepareNodes, setNodes]);


  return (
    <div style={{ width: '100%', height: '100%' }}>
      <ReactFlow
        nodes={nodes}
        edges={edges}
        onNodesChange={onNodesChange}
        onEdgesChange={onEdgesChange}
        onConnect={onConnect}
        proOptions={{
          hideAttribution: true,
        }}
        nodeTypes={nodeTypes}
        nodesDraggable={true}
        onNodeDragStop={(event, node) => {
          console.log('Node ID:', node);
          console.log('New Position:', node.position);
          if(node.position.x !== node.data.position.x || node.position.y !== node.data.position.y) {
            mutation.mutate({
              projectId: node.data.project,
              id: node.data._id,
              body: {
                position: node.position,
              }
            })
          }
        }}
      >
        <Controls />
        <Panel />
        <NodeToolbar />
        {/* <MiniMap /> */}
        <Background variant="dots" gap={12} size={1} />
      </ReactFlow>
    </div>
  );
}
