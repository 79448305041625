import { Button } from 'antd';
import classNames from 'classnames';
import React from 'react';
import { TbPlus } from 'react-icons/tb';
import { Link } from 'react-router-dom';
import './UIButton.scss';

export default function UIButton({
  title,
  icon = <TbPlus />,
  onClick,
  theme = 'default',
  disabled = false,
  type = 'button',
  loading = false,
  link,
}) {
  const Element = link ? Link : 'span';
  return (
    <Element to={link}>
      <Button
        className={classNames(
          'btn ui-btn btn-add d-inline-flex align-items-center',
          {
            'theme-default': theme === 'default',
            'theme-primary': theme === 'primary',
          }
        )}
        htmlType={type}
        onClick={onClick}
        disabled={disabled}
        loading={loading}
        icon={icon}
      >
        {/* { icon} */}
        <span>{title}</span>
      </Button>
    </Element>
  );
}
