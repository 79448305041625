import React from 'react'
import './PageContent.scss'

export default function PageContent({ children }) {
  return (
    <div className='page-content'>
        { children }
    </div>
  )
}
