import { getProjectApi } from "../../services/api.service";
import { setProjectAction } from "./actions";

export const fetchProjectEffect = async (dispatch, projectId) => {
  try {
    const { data } = await getProjectApi(projectId);
    dispatch(setProjectAction(data));
  } catch (error) {
    // handle error, perhaps dispatch an error state or show a notification
  }
};
