'use client';
import { Form, Modal, Switch } from 'antd';
import React from 'react';
import { TbCheck } from 'react-icons/tb';
import { enableModuleApi } from '../services/api.service';
import { PROJECT_MODALS } from '../context/projectContext';
import UIButton from '../components/ui/UIButton/UIButton';
import { useProject } from '../context/project';
import { hideModalAction, updateDeployingStatus } from '../context/project/actions';
import { useMutation, useQueryClient } from 'react-query';
import { fetchProjectEffect } from '../context/project/effects';
import ModalOption from '../components/ui/ModalOption/ModalOption';

const EnableAuthentication = ({ project, onHide }) => {
  
  const { dispatch } = useProject();
  const mutation = useMutation((values) => enableModuleApi(project._id, 'authentication', values), {
    onSuccess: () => {
      fetchProjectEffect(dispatch, project._id);
    }
  });

  const handleSubmit = (values) => {
    console.log(values, project);
    dispatch(updateDeployingStatus(true));
    setTimeout(() => {
      mutation.mutate(values);
      dispatch(updateDeployingStatus(false));
    }, 2000);
    onHide();
  };

  return (
    <Form
      onFinish={handleSubmit}
      initialValues={{
        enableMobileLogin: false,
      }}
      className='enable-authentication-form'
    >
      <p>This will enable the Authentication Module for this project.</p>
      <div className="options-wrapper">
        <ModalOption id={'enableMobileLogin'} title={'Enable Mobile Login'}>
          Enabling Mobile Login will allow users to login to your application using their mobile phone number.
        </ModalOption>
        <ModalOption id={'enableSocialLogin'} title={'Enable Social Login'} checked={false}>
          Enabling Social Login will allow users to login to your application using their Facebook or Google accounts.
          {/* <div className="py-2">
            <div className='d-flex align-items-center py-1'>
              <Switch size='small' id='facebook' checked={true} />
              <label htmlFor='facebook' className='ms-2'>Facebook Login</label>
            </div>
            <div className='d-flex align-items-center py-1'>
              <Switch size='small' id='google' checked={true} />
              <label htmlFor='google' className='ms-2'>Google Login</label>
            </div>
          </div> */}
        </ModalOption>

        <ModalOption id={'enableCustomFields'} title={'Enable Custom Fields'}>
          <p>Lorem ipsum dolor, sit amet consectetur adipisicing elit. Dolor, voluptatum?</p>
        </ModalOption>
      </div>
      <UIButton
        title={'Enable Authentication'}
        theme="primary"
        icon={<TbCheck />}
        type="submit"
      />
    </Form>
  );
};

export default function EnableAuthenticationModal() {
  const state = useProject();
  const { project, modals } = state;
  const { showEnableAuthenticationModal } = modals;

  const handleHideModal = () => {
    state.dispatch(hideModalAction(PROJECT_MODALS.ENABLE_AUTHENTICATION_MODAL));
  };

  return (
    <Modal
      title="Enable Authentication"
      onCancel={handleHideModal}
      open={showEnableAuthenticationModal}
      footer={false}
    >
      <EnableAuthentication project={project} onHide={handleHideModal} />
    </Modal>
  );
}
