import React from 'react';
import ProjectSection from '../../components/sections/ProjectSection/ProjectSection';
import { useProject } from '../../context/project';
import { selectProject } from '../../context/project/selectors';
import { useQuery } from 'react-query';
import { getTables } from '../../services/api.service';
import { Card, Col, Row } from 'antd';
import ContentSection from '../../components/sections/ContentSection/ContentSection';
import { Link } from 'react-router-dom';

export default function ProjectDataCRM() {
  const state = useProject();
  const project = selectProject(state);
  const projectId = project?._id;
  const {
    data: collections = [],
    error,
    isLoading,
  } = useQuery(['tables', projectId], () => getTables(projectId), {
    enabled: !!projectId,
  });
  return (
    <ProjectSection title={'Database CMS'}>
      <ContentSection>
        <Row gutter={20}>
          {collections.map((collection) => {
            return (
              <Col span={8}>
                <Card title={collection.title} bordered={false}>
                  <Link to={`/projects/${projectId}/data/${collection._id}`}>
                    View Data
                  </Link>
                </Card>
              </Col>
            );
          })}
        </Row>
      </ContentSection>
    </ProjectSection>
  );
}
