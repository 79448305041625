import { Modal } from 'antd';
import React from 'react';
import CollectionTableForm from '../../components/projects/ProjectSchemaBuilder/CollectionTableForm/CollectionTableForm';
import { MODALS, useModals } from '../../context/modalContext';
import { useParams } from 'react-router-dom';
import { getSingleTable } from '../../services/api.service';
import { useQuery } from 'react-query';

export default function UpdateCollectionModal() {
  
  const params = useParams();
  const project = params.project;
  const collectionId = params.collection;

  const { modals, data, hideModal } = useModals();
  const visible = modals?.showUpdateSchemaModal;
  const modalData = modals?.data;
  const selectedCollection = modalData?.selectedCollection;

  const closeModal = () => {
    hideModal(MODALS.UPDATE_SCHEMA_MODAL);
  }

  const {
    data: collection,
    loading,
    error,
  } = useQuery(
    ['tables', project, collectionId],
    () => getSingleTable(project, collectionId),
    {
      enabled: !!(project && collectionId),
    }
  );

  return (
    <Modal
      open={visible}
      title={'Update Schema'}
      footer={false}
      width={900}
      onCancel={() => closeModal(false)}
    >
      {/* <div className="schema-editor-wrapper">
        <SchemaEditor onChange={handleSchemaUpdate} />
      </div> */}
      {collection && <div className="schema-editor-form">
        <CollectionTableForm
          collection={collection}
          onClose={() => closeModal(false)}
        />
      </div> }
    </Modal>
  );
}
