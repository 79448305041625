import React from 'react';
import SettingsPage from './SettingPage';
import { Button, Form, Input, notification } from 'antd';
import { updatePassword } from '../../services/api.service';
import { useAuth } from '../../context/authContext';
import UIButton from '../../components/ui/UIButton/UIButton';
import { TbCheck } from 'react-icons/tb';

export default function PasswordSettings() {
  const [form] = Form.useForm();
  const { user, setUser } = useAuth();
  console.log('user', user);
  const handleFormSubmit = (values) => {
    form.resetFields();
    if (values.new_password === values.confirm_password) {
      updatePassword({
        password: values.current_password,
        newPassword: values.new_password,
      }).then(({ data }) => {
        console.log('data', data);
      });
    } else {
      notification.error({
        message: 'Error',
        description: 'New password and confirm password does not match.',
      });
    }
  };

  return (
    <SettingsPage title={"Update your Password"}>
      <Form layout="vertical" form={form} onFinish={handleFormSubmit}>
        <Form.Item name={'current_password'} label="Current Password">
          <Input size="large" type="password" />
        </Form.Item>
        <Form.Item name={'new_password'} label="New Password">
          <Input size="large" type="password" />
        </Form.Item>
        <Form.Item name={'confirm_password'} label="Confirm Password">
          <Input size="large" type="password" />
        </Form.Item>
        <Form.Item>
          <UIButton type="submit" title={'Update Account'} icon={<TbCheck />} />
        </Form.Item>
      </Form>
    </SettingsPage>
  );
}
