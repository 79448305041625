import { Card, Col, Empty, Row, Space } from 'antd';
import React, { useEffect } from 'react';
import { getProjectsApi } from '../../services/api.service';
import ProjectCard from '../../components/projects/ProjectCard/ProjectCard';
import { useNavigate } from 'react-router-dom';
import UIButton from '../../components/ui/UIButton/UIButton';

export default function ProjectList({ onAddClick }) {
  const [projects, setProjects] = React.useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    getProjectsApi().then(({ data }) => {
      console.log(data);
      setProjects(data);
    });
  }, []);

  const openProjectForEditing = (project) => {
    console.log('open project for editing');
    navigate('/projects/' + project._id);
  };

  return (
    <Row gutter={[20, 20]}>
      {projects?.map((project) => (
        <Col span={8} key={project._id}>
          <ProjectCard
            project={project}
            onClick={() => openProjectForEditing(project)}
          />
        </Col>
      ))}
      {projects.length === 0 && (
        <Col span={24} className="bg-white p-5">
          <Empty description="Create your first project" >
            <UIButton title="Create Project" onClick={onAddClick}/>
          </Empty>
        </Col>
      )}
    </Row>
  );
}
