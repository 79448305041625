import { MODALS, ModalContext, useModals } from '../context/modalContext';
import { createProjectApi, getSingleTemplates } from '../services/api.service';
import { Badge, Button, Form, Input, Modal, Spin, Switch, Tag } from 'antd';
import React, { useContext, useEffect, useState } from 'react';
import { TbCheck } from 'react-icons/tb';

import UIButton from '../components/ui/UIButton/UIButton';
import { useNavigate } from 'react-router-dom';
import ModalOption from '../components/ui/ModalOption/ModalOption';

const steps = ['Setting up the Project', 'Configuring server', 'Deploying'];

const CreateProjectModalContent = ({ onHide, onCreate, data }) => {
  const [template, setTemplate] = useState(null);

  const handleCreateProject = (values) => {
    if (template) {
      values.template = template._id;
    }
    const project = {
      title: values.title,
      description: values.description,
      template: values.template,
    }
    createProjectApi(project).then(({ data }) => {
      console.log('created', data);
      onCreate(data);
    });
  };

  useEffect(() => {
    if (data?.selectedTemplate) {
      getSingleTemplates(data?.selectedTemplate).then(
        ({ data: templateData }) => {
          console.log(templateData);
          setTemplate(templateData);
        }
      );
    }
  }, [data]);

  return (
    <div>
      <div className="py-4 create-project-modal">
        <Form layout="vertical" onFinish={handleCreateProject}>
          <Form.Item name={'title'} label="Give your project a Name">
            <Input placeholder="Awesome New REST API" size="large" />
          </Form.Item>

          {/* <Form.Item name={'description'} label="Describe your Project">
            <Input.TextArea placeholder="Awesome New REST API" rows={3} />
          </Form.Item> */}

          {template && (
            <Form.Item>
              <h5 className="fs-6 mb-2 pb-2 border-bottom">Templates</h5>
              <Badge.Ribbon
                placement="start"
                text="Selected Template"
                color="#6610c1"
              >
                <div className="well p-4 border bg-light">
                  <h6 className="fw-bold mt-4">{template?.title}</h6>
                  <p className="mb-0">{template?.shortDescription}</p>
                </div>
              </Badge.Ribbon>
            </Form.Item>
          )}
          <ModalOption
            title={'Use NodeCompose AI to generate the project initial data'}
            id={'enableAuthentication'}
          >
            <Form.Item
              name={'projectTitle'}
              label="Give an example of application which is similar to yours"
            >
              <Input placeholder="Uber Eats" />
            </Form.Item>
            <Form.Item name={'projectDesc'} label="Describe your Project">
              <Input.TextArea
                rows={4}
                placeholder="Uber eats is a food delivery application which allows users to order food from their favorite restaurants."
              />
            </Form.Item>
          </ModalOption>
          {/* <div className="enable-modules-list">
            <h5 className="fs-6 mb-2 pb-2 border-bottom">Configuration</h5>
            <Form.Item>
              <div className="d-flex align-items-center justify-content-between">
                <span>User Authentication : </span>
                <Switch />
              </div>
            </Form.Item>

            <Form.Item>
              <div className="d-flex align-items-center justify-content-between">
                <span>Emails &amp; Notifications : </span>
                <Switch />
              </div>
            </Form.Item>

            <Form.Item>
              <div className="d-flex align-items-center justify-content-between">
                <span>Payment &amp; Checkout : </span>
                <Switch />
              </div>
            </Form.Item>
          </div> */}

          <UIButton
            theme="primary"
            title={'Create Project'}
            icon={<TbCheck size={16} />}
            type="submit"
          />
        </Form>
      </div>
    </div>
  );
};

export const LoadingModal = ({ onHide, data }) => {


  const navigate = useNavigate();
  const [createState, setCreateState] = useState({
    current: 0,
  });
  const currentStepInfo = steps[createState.current];

  useEffect(() => {
    setTimeout(() => {
      setCreateState({
        current: 1,
      });
      setTimeout(() => {
        setCreateState({
          current: 2,
        });
        setTimeout(() => {
          console.log('Hiding modal');
          onHide();
          navigate('/projects/' + data._id);
        }, 3000);
      }, 3000);
    }, 3000);
  }, []);

  return (
    <div className="creating-project-modal loading-project-modal text-center">
      <img src="/images/loader-ai-optimize.gif" className="image-ai-loader" />
      <h4 className="mt-5 fs-5 pb-5">{currentStepInfo}...</h4>
    </div>
  );
};

export default function CreateProjectModal() {
  const { modals, hideModal } = useModals();
  const { showCreateProjectModal, data } = modals;

  const [showLoading, setShowLoading] = useState(false);
  const [projectData, setProjectData] = useState(null);

  const hideCurrentModal = () => {
    if(showLoading) {
      return;
    }
    hideModal(MODALS.CREATE_PROJECT_MODAL);
  };

  const hideLoading = () => {
    hideModal(MODALS.CREATE_PROJECT_MODAL);
  }

  const handleCreate = (data) => {
    setShowLoading(true);
    setProjectData(data);
  }

  useEffect(() => {
    if(showCreateProjectModal) {
      setShowLoading(false);
    }
  }, [showCreateProjectModal]);

  return (
    <Modal
      open={showCreateProjectModal}
      width={showLoading ? 600 : 600}
      footer={false}
      title={!showLoading ? "Create a New Project" : false}
      onCancel={hideCurrentModal}
      destroyOnClose={true}
      closeIcon={!showLoading}
      // closable={false}
    >
      { !showLoading && <CreateProjectModalContent onHide={hideCurrentModal} data={data} onCreate={handleCreate} /> }
      { showLoading && <LoadingModal onHide={hideLoading} data={projectData} /> }
    </Modal>
  );
}
