import React from 'react';
import { Card, Col, Row, Statistic } from 'antd';
import GettingStartChecklist from '../../components/sections/GettingStartedChecklist/GettingStartedChecklist';
import { getDashboardStats } from '../../services/api.service';

export default function DashboardContainer() {
  const [stats, setStats] = React.useState({
    totalProjects: 0,
    activeProjects: 0,
    totalCollections: 0,
    notifications: 0,
    projects: []
  });

  React.useEffect(() => {
    // fetch stats
    getDashboardStats().then(({ data: res }) => {
      setStats({
        totalProjects: res.totalProjects,
        activeProjects: res.activeProjects,
        totalCollections: res.totalCollections,
        notifications: res.notifications,
        projects: res.projects
      });
    });
  }, []);
  return (
    <div className="container dashboard-page">
      <Row gutter={16}>
        <Col span={8}>
          <Card bordered={false}>
            <Statistic
              title="Total Projects"
              value={stats.totalProjects.toString().padStart(2, '0')}
              // prefix={<TbFolder />}
            />
          </Card>
        </Col>
        <Col span={8}>
          <Card bordered={false}>
            <Statistic
              title="Active Projects"
              value={stats.totalProjects.toString().padStart(2, '0')}
              // prefix={<TbFolder />}
            />
          </Card>
        </Col>
        <Col span={8}>
          <Card bordered={false}>
            <Statistic
              title="Total Collections"
              value={stats.totalCollections.toString().padStart(2, '0')}
            />
          </Card>
        </Col>
        {/* <Col span={6}>
          <Card bordered={false}>
            <Statistic title="Notifications" value={'00'} />
          </Card>
        </Col> */}
      </Row>
      <Row>
        <Col span={24}>
          {/* <h4 className='mt-5 fs-5'>Your getting started checklist 👇</h4> */}
          <h4 className="mt-5 fs-5">Get started now 👇</h4>
        </Col>
        <Col span={24}>
          <GettingStartChecklist projects={stats.projects} />
        </Col>
      </Row>
    </div>
  );
}
