import React from 'react';
import { Card } from 'antd';
import classNames from 'classnames';
import { TbCalendar, TbClock } from 'react-icons/tb';

const ProjectStatus = ({ status }) => {
  // Draft, Pending, Running, Failed, Completed
  return (
    <div className={classNames('project-status', status)}>
      <span className="dot"></span>
      <span className="status-text text-capitalize">{status}</span>
    </div>
  );
};

export default function ProjectCard({ project, onClick }) {
  return (
    <Card
      className="project-card"
      title={project.title}
      hoverable={true}
      onClick={onClick}
    >
      <p className="project-desc">{project.description}</p>
      <ProjectStatus status={project.status} />
      <div className="project-date d-flex align-items-center">
        <TbClock />
        <span className="ms-1">{project.createdAt}</span>
      </div>
    </Card>
  );
}
