export const selectProject = (state) => state.project;

export const checkIfModuleIsEnabled = (state, moduleName) => {
  console.log(state, moduleName);
  const moduleExists = state.project?.modules?.find(
    (module) => module.name === moduleName
  );
  if (moduleExists) {
    return true;
  }
  return false;
}

export const checkIfProjectIsSaved = (state) => state.project?.isDeployed;

export const checkIfProjectIsDeployed = (state) => state.project?.isDeployed;

export const selectProjectDeploying = (state) => state.deploying;

export const selectProjectSchema = (state) => selectProject(state).schema;

export const selectModal = (state, modal) => state.modals[modal];

export const selectActiveCollection = (state) => state.activeCollection;

export const selectSchemaForCollection = (state, id) =>
  state.updatedCollections[id];

export const selectIfCollectionUpdated = (state) => {
  const id = selectActiveCollection(state)?._id;
  return !!state.updatedCollections[id];
};
