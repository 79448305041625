'use client';
import { Modal } from 'antd';
import React, { use, useEffect, useState } from 'react';
import { TbCheck, TbRocket } from 'react-icons/tb';
import { MODALS, useModals } from '../context/modalContext';
import { deployProjectApi, getProjectApi } from '../services/api.service';
import { PROJECT_MODALS } from '../context/projectContext';
import UIButton from '../components/ui/UIButton/UIButton';
import { useProject } from '../context/project';
import { hideModalAction, setProjectAction, setSavedAction } from '../context/project/actions';
import { selectModal, selectProject } from '../context/project/selectors';
// import { useProject } from '../context/project';

export default function DeployProjectModal({ onStateChange }) {
  const [currentState, setCurrentState] = useState('deploy');
  const state = useProject();
  const project = selectProject(state);
  const showDeployModal = selectModal(state, 'showDeployModal');
  const { dispatch } = useProject();
  // const { project, modals, saveProject, hideModal } = useProject();
  // const { showDeployModal } = modals;

  const deployChanges = () => {
    setCurrentState('deploying');
    onStateChange('deploying');
    deployProjectApi(project._id).then(({ data }) => {
      const { response, result, project } = data;
      // saveProject(project);
      state.dispatch(setProjectAction(project));
    });
    setTimeout(() => {
      setCurrentState('deployed');
      onStateChange('deployed');
      dispatch(setSavedAction(true));
    }, 2500);
  };

  const goToAPIDocs = () => {
    // open docs in new tab
    console.log('project', project);
    const url = project?.url;
    window.open(url + '/api-docs');
  }

  const hideCurrentModal = () => {
    // hideModal(PROJECT_MODALS.DEPLOY_MODAL);
    state.dispatch(hideModalAction(PROJECT_MODALS.DEPLOY_MODAL));
    setCurrentState('deploy');
  }

  return (
    <Modal open={showDeployModal} title={false} footer={false} onCancel={hideCurrentModal}>
      <div className="deploy-changes-modal">
        {(currentState === 'deploy' || currentState === 'deploying') && (
          <div className="">
            <h4 className="fs-5 fw-bold">
              {currentState === 'deploy'
                ? 'Are you ready to deploy your changes?'
                : 'Deploying your changes'}
            </h4>
            <p>
              Deploying new changes will overwrite the current version of the
              project on the server.
            </p>
            <UIButton
              title="Deploy Changes"
              theme="primary"
              icon={<TbCheck size={18} />}
              onClick={deployChanges}
              loading={currentState === 'deploying'}
            />
          </div>
        )}
        {currentState === 'deployed' && (
          <div className="changes-pushed text-center">
            <img src="/images/success.png" />
            <h4 className="fs-5 mt-4">New Changes Deployed</h4>
            <p>New changes have been deployed to the server.</p>
            <UIButton
              title="View Project"
              theme="primary"
              icon={<TbRocket size={18} />}
              onClick={goToAPIDocs}
            />
          </div>
        )}
      </div>
    </Modal>
  );
}
