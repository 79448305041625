import React from 'react';
import { Link } from 'react-router-dom';
import './ContentSection.scss'

export default function ContentSection({ links = [], children }) {
  return (
    <div className="content-section">
      {links.length > 0 && (
        <div className="page-content-navigation">
          <ul>
            {links.map((link, index) => {
              return (
                <li key={index}>
                  <Link to={link.to} className={link.active ? 'active' : ''}>
                    {link.title}
                  </Link>
                </li>
              );
            })}
          </ul>
        </div>
      )}
      <div className="page-content-section">{children}</div>
    </div>
  );
}
