import React, { useEffect } from 'react';
import './MainLayout.scss';
import Header from '../../components/core/Header/Header';
import Footer from '../../components/core/Footer/Footer';
import { Layout } from 'antd';
// import { ProjectProvider } from '../../context/projectContext';
import { useAuth } from '../../context/authContext';
import { ProjectProvider } from '../../context/project';

export default function MainLayout({ children }) {

  const { user } = useAuth();

  useEffect(() => {
    // if(user) {
    //   window.location.href = '/';
    // }
    // if(user === null) {
    //   window.location.href = '/login';
    // }
  }, [user]);

  return (
    <Layout>
      <Layout.Header>
        <Header />
      </Layout.Header>
      {/* <ProjectProvider> */}
        <Layout.Content>
          <div className="main-layout">{children}</div>
        </Layout.Content>
      {/* </ProjectProvider> */}
      {/* <Layout.Footer></Layout.Footer> */}
    </Layout>
  );
}
