import { Card, Tooltip } from 'antd';
import moment from 'moment';
import React from 'react';
import {
  Bar,
  BarChart,
  CartesianGrid,
  Legend,
  ResponsiveContainer,
  XAxis,
  YAxis,
} from 'recharts';
import './ProjectQueriesChart.scss';

const data = [
  {
    name: 'Page A',
    uv: 4000,
    pv: 2400,
    amt: 2400,
  },
  {
    name: 'Page B',
    uv: 3000,
    pv: 1398,
    amt: 2210,
  },
  {
    name: 'Page C',
    uv: 2000,
    pv: 9800,
    amt: 2290,
  },
  {
    name: 'Page D',
    uv: 2780,
    pv: 3908,
    amt: 2000,
  },
  {
    name: 'Page E',
    uv: 1890,
    pv: 4800,
    amt: 2181,
  },
  {
    name: 'Page F',
    uv: 2390,
    pv: 3800,
    amt: 2500,
  },
  {
    name: 'Page G',
    uv: 3490,
    pv: 4300,
    amt: 2100,
  },
];

export default function ProjectQueriesChart({ title }) {
  const data = [];

  for(let i = 0; i < 7; i++) {
    data.push({
      name: moment().subtract(i, 'days').format('DD MMM'),
      uv: Math.max(Math.floor(Math.random() * 1000), 310),
    });
  }
  return (
    <Card bordered={false} className='project-queries-chart'>
      <h4 className='fs-6 mb-4 text-center'>{ title }</h4>
      <div style={{ height: '350px', position: 'relative' }}>
        <div className='traffic-data-here'>
          <p>
            Please wait for some time to see the traffic data here.
          </p>
        </div>
        <ResponsiveContainer width="100%" height="100%">
          <BarChart width={40} height={40} data={data.reverse()}>
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="name" />
            <YAxis />
            <Tooltip />
            {/* <Legend /> */}
            <Bar dataKey="uv" fill="#8884d8" width={10} barSize={20} />
          </BarChart>
        </ResponsiveContainer>
      </div>
    </Card>
  );
}
