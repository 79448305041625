module.exports = {
  countriesSchema: {
    title: 'Countries',
    attributes: [
      {
        id: 0,
        title: 'id',
        type: 'String',
        primaryKey: true,
      },
      {
        id: 1,
        title: 'name',
        type: 'String',
      },
      {
        id: 2,
        title: 'code',
        type: 'String',
      },
      {
        id: 4,
        title: 'region',
        type: 'String',
      },
      {
        id: 5,
        title: 'status',
        type: 'String',
      },
      {
        id: 6,
        title: 'enabled',
        type: 'Boolean',
      },
    ],
  },
  stateSchema: {
    title: 'State',
    attributes: [
      {
        id: 0,
        title: 'id',
        type: 'String',
        primaryKey: true,
      },
      {
        id: 1,
        title: 'name',
        type: 'String',
      },
      {
        id: 2,
        title: 'code',
        type: 'String',
      },
      {
        id: 2,
        title: 'countryId',
        type: 'String',
        foreignKey: true,
      },
      {
        id: 5,
        title: 'status',
        type: 'String',
      },
      {
        id: 6,
        title: 'enabled',
        type: 'Boolean',
      },
    ],
  },
  citySchema: {
    title: 'City',
    attributes: [
      {
        id: 0,
        title: 'id',
        type: 'String',
        primaryKey: true,
      },
      {
        id: 1,
        title: 'name',
        type: 'String',
      },
      {
        id: 2,
        title: 'code',
        type: 'String',
      },
      {
        id: 2,
        title: 'stateId',
        type: 'String',
        foreignKey: true,
      },
      {
        id: 5,
        title: 'status',
        type: 'String',
      },
      {
        id: 6,
        title: 'enabled',
        type: 'Boolean',
      },
    ],
  },
  categorySchema: {
    title: 'Category',
    attributes: [
      { id: 0, title: 'id', type: 'String', primaryKey: true },
      { id: 1, title: 'name', type: 'String' },
      { id: 2, title: 'description', type: 'String' },
      { id: 3, title: 'parentCategoryId', type: 'String', foreignKey: true },
      { id: 4, title: 'image', type: 'String' },
      { id: 5, title: 'status', type: 'String' },
      { id: 6, title: 'enabled', type: 'Boolean' },
    ],
  },
  neighbourhoodTypeSchema: {
    title: 'NeighbourhoodType',
    attributes: [
      { id: 0, title: 'id', type: 'String', primaryKey: true },
      { id: 1, title: 'name', type: 'String' },
      { id: 2, title: 'description', type: 'String' },
      { id: 3, title: 'status', type: 'String' },
      { id: 4, title: 'enabled', type: 'Boolean' },
    ],
  },
  neighbourhoodSchema: {
    title: 'Neighbourhood',
    attributes: [
      { id: 0, title: 'id', type: 'String', primaryKey: true },
      { id: 1, title: 'name', type: 'String' },
      { id: 2, title: 'description', type: 'String' },
      { id: 3, title: 'typeId', type: 'String', foreignKey: true },
      { id: 4, title: 'cityId', type: 'String', foreignKey: true },
      { id: 5, title: 'status', type: 'String' },
      { id: 6, title: 'enabled', type: 'Boolean' },
    ],
  },
  propertySchema: {
    title: 'Properties',
    attributes: [
      { id: 0, title: 'id', type: 'String', primaryKey: true },
      { id: 1, title: 'hostId', type: 'String', foreignKey: true },
      { id: 2, title: 'title', type: 'String' },
      { id: 3, title: 'description', type: 'String' },
      { id: 4, title: 'address', type: 'String' },
      { id: 5, title: 'cityId', type: 'String', foreignKey: true },
      { id: 6, title: 'neighbourhoodId', type: 'String', foreignKey: true },
      { id: 7, title: 'country', type: 'String' },
      { id: 8, title: 'zipCode', type: 'String' },
      { id: 9, title: 'latitude', type: 'Number' },
      { id: 10, title: 'longitude', type: 'Number' },
      { id: 11, title: 'propertyType', type: 'String' }, // e.g. "Apartment", "House", "Loft", etc.
      { id: 12, title: 'bedrooms', type: 'Number' },
      { id: 13, title: 'bathrooms', type: 'Number' },
      { id: 14, title: 'amenities', type: 'String' }, // Can be comma-separated or might point to another schema/table.
      { id: 15, title: 'maxGuests', type: 'Number' },
      { id: 16, title: 'images', type: 'String' }, // URLs to the images, comma-separated or might point to another schema.
      { id: 17, title: 'pricePerNight', type: 'Number' },
      { id: 18, title: 'minimumStay', type: 'Number' },
      { id: 19, title: 'availabilityStart', type: 'Date' },
      { id: 20, title: 'availabilityEnd', type: 'Date' },
      { id: 21, title: 'rating', type: 'Number' },
      { id: 22, title: 'reviewCount', type: 'Number' },
      { id: 23, title: 'petsAllowed', type: 'Boolean' },
      { id: 24, title: 'childrenAllowed', type: 'Boolean' },
      { id: 25, title: 'smokingAllowed', type: 'Boolean' },
      { id: 26, title: 'parkingAvailable', type: 'Boolean' },
      { id: 27, title: 'wifiAvailable', type: 'Boolean' },
      { id: 28, title: 'wheelchairAccessible', type: 'Boolean' },
      { id: 29, title: 'elevatorInBuilding', type: 'Boolean' },
      { id: 30, title: 'poolAvailable', type: 'Boolean' },
      { id: 31, title: 'gymAccess', type: 'Boolean' },
      { id: 32, title: 'status', type: 'String' }, // e.g. "Listed", "Unlisted", "Booked", etc.
      { id: 33, title: 'creationDate', type: 'Date' },
      { id: 34, title: 'lastUpdated', type: 'Date' },
      { id: 35, title: 'houseRules', type: 'String' },
      { id: 36, title: 'cancellationPolicy', type: 'String' }, // e.g. "Flexible", "Moderate", "Strict"
      { id: 37, title: 'securityDeposit', type: 'Number' },
      { id: 38, title: 'cleaningFee', type: 'Number' },
      { id: 39, title: 'checkInTime', type: 'String' }, // This can be a time range or specific time.
      { id: 40, title: 'checkOutTime', type: 'String' },
      { id: 41, title: 'verified', type: 'Boolean' }, // Indicates if the property details are verified by the platform.
    ],
  },

  hostSchema: {
    title: 'Hosts',
    attributes: [
      { id: 0, title: 'id', type: 'String', primaryKey: true },
      { id: 2, title: 'fullName', type: 'String' },
      { id: 3, title: 'email', type: 'String' },
      { id: 4, title: 'phoneNumber', type: 'String' },
      { id: 5, title: 'profileImage', type: 'String' },
      { id: 6, title: 'description', type: 'String' },
      { id: 7, title: 'dateJoined', type: 'Date' },
      { id: 8, title: 'identityVerified', type: 'Boolean' },
      { id: 9, title: 'responseRate', type: 'Number' },
      { id: 10, title: 'responseTime', type: 'String' },
      { id: 11, title: 'superhostStatus', type: 'Boolean' },
      { id: 12, title: 'numberOfProperties', type: 'Number' },
      { id: 13, title: 'averageRating', type: 'Number' },
      { id: 14, title: 'totalReviews', type: 'Number' },
      { id: 15, title: 'address', type: 'String' },
      { id: 16, title: 'city', type: 'String' },
      { id: 17, title: 'country', type: 'String' },
      { id: 18, title: 'zipCode', type: 'String' },
      { id: 20, title: 'status', type: 'String' },
      { id: 21, title: 'lastLogin', type: 'Date' },
    ],
  },
  reviewSchema: {
    title: 'Reviews',
    attributes: [
      { id: 0, title: 'id', type: 'String', primaryKey: true },
      { id: 1, title: 'propertyId', type: 'String', foreignKey: true }, // Link to the property that was reviewed.
      { id: 2, title: 'userId', type: 'String', foreignKey: true }, // ID of the user who left the review.
      { id: 3, title: 'rating', type: 'Number' }, // Typically out of 5 or 10.
      { id: 4, title: 'title', type: 'String' }, // Short summary of the review, e.g., "Great stay!"
      { id: 5, title: 'body', type: 'String' }, // Detailed review content.
      { id: 6, title: 'datePosted', type: 'Date' },
      { id: 7, title: 'likes', type: 'Number' }, // Number of users who found this review helpful.there's a response from the host or management.
      { id: 9, title: 'photos', type: 'String' }, // URLs to any photos the user might have uploaded with their review.
      { id: 10, title: 'verifiedStay', type: 'Boolean' }, // Indicates if the user really stayed at the property (e.g., booked through the platform).
      { id: 11, title: 'status', type: 'String' }, // e.g., "Published", "Flagged", "Removed", etc.
    ],
  },
};
