export const activeClassFunc = ({ isActive, isPending }) =>
  isPending ? 'pending' : isActive ? 'active' : '';

export const capitalizeCollection = (str) => {
  return str
    .split(' ')
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
    .join('');
}

export const convertSchemaObjectToSchemaArray = (schemaObject) => {
  const schemaArray = [];
  console.log('schemaObject', schemaObject, typeof schemaObject);
  if(typeof schemaObject === 'object') {
    Object.keys(schemaObject).forEach((key) => {
      schemaArray.push({
        id: key,
        title: key,
        name: key,
        type: schemaObject[key].type,
        primaryKey: schemaObject[key].primaryKey,
        foreignKey: schemaObject[key].foreignKey,
        required: schemaObject[key].required,
        defaultValue: schemaObject[key].defaultValue,
      });
    });
  }
  return schemaArray;
}