import { Button, Checkbox, Form, Modal, Select } from 'antd';
import React from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useProject } from '../../context/project';
import { createRouteCRUD, getTables } from '../../services/api.service';

export default function GenerateCRUDModal({ show, onClose }) {

  const { project } = useProject();
  const projectId = project?._id;
  const queryClient = useQueryClient();

  const mutation = useMutation(createRouteCRUD, {
    onSuccess: (data) => {
      console.log('Success', data);
      queryClient.invalidateQueries('routes');
    }
  });

  const { data: tables = [] } = useQuery(['tables', projectId], () => getTables(projectId), {
    enabled: !!projectId,
  });

  const handleSubmit = (values) => {
    console.log(values);
    mutation.mutate({
      projectId,
      ...values
    })
    onClose();
  }

  return (
    <Modal open={show} footer={false} title="Auto Generate CRUD APIs" onCancel={onClose}>
      <div className="mt-3 pt-3 border-top">
        <Form layout="vertical" onFinish={handleSubmit}>
          <Form.Item label="Select Database Schema" name={"table"}>
            <Select>
              { tables.map((table) => (
                <Select.Option key={table._id} value={table._id}>
                  {table.title}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item name={"createRouteGroup"} valuePropName="checked">
            <Checkbox value={true}>
              Also Create Route Group for this Schema (if not exists)
            </Checkbox>
          </Form.Item>
          <Form.Item>
            <Button htmlType='submit' type="primary">Generate</Button>
          </Form.Item>
        </Form>
      </div>
    </Modal>
  );
}
