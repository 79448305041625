import { Button, Col, Empty, Row } from 'antd';
import React from 'react';
import UIButton from '../../ui/UIButton/UIButton';
import Link from 'antd/es/typography/Link';

const ChecklistItem = ({ title, children, hasCheckbox = true }) => {
  return (
    <div className="checklist-item">
      {title && (
        <div className="checklist-heading">
          {hasCheckbox && <span className="checkbox"></span>}
          <span className="title">{title}</span>
        </div>
      )}
      <div className="checklist-content">{children}</div>
    </div>
  );
};

export default function GettingStartChecklist({ projects }) {
  return (
    <div className="getting-start-checklist">
      <div className="checklist">
        <ChecklistItem
          title={
            projects.length === 0
              ? 'Create your First Project'
              : 'View your created Projects'
          }
        >
          {projects.length === 0 && (
            <Empty
              description={
                "You don't have any project created. Create a new project."
              }
            >
              <UIButton title={'Pick a Template'} />
            </Empty>
          )}
          {projects.length !== 0 && (
            <Empty description={'View your created Projects'}>
              <Button type="dashed" >
                <Link href="/projects">View Projects</Link>
              </Button>
            </Empty>
          )}
        </ChecklistItem>
        <Row gutter={[20, 20]} className="mt-4">
          <Col span={12}>
            <ChecklistItem hasCheckbox={false}>
              <h4 className="fs-5">Connect with a system design expert</h4>
              <p className="">
                Get on a free call with our system design expert to discuss your
                scaling challenges. We will help you get started with the right
                architecture.
              </p>
              <Button type="dashed">Schedule a call</Button>
            </ChecklistItem>
          </Col>
          <Col span={12}>
            <ChecklistItem hasCheckbox={false}>
              <h4 className="fs-5">24x7 help at your fingertips</h4>
              <p className="">
                Get all the answers on our knowledge base. Still need help?
                Reach out to us 24x7 on chat or email us at
                support@nodecompose.com
              </p>
              <Button
                type="dashed"
                htmlType="a"
                href="mailto:support@nodecompose.com"
              >
                Chat with us
              </Button>
            </ChecklistItem>
          </Col>
        </Row>
      </div>
    </div>
  );
}
