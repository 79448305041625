export const convertRouteToRouteJson = (route) => {
  // projectId: project?._id,
  //     body: {
  //       path: json.path,
  //       method: json.method,
  //       routeGroup: routeData.routeGroup,
  //       inputs: inputs.map((input) => ({
  //         label: input.label,
  //         type: input.type,
  //         required: input.required,
  //         hasStaticValue: input.hasStaticValue,
  //         in: input.inputType,
  //         inputType: input.type.toLowerCase(),
  //       })),
  //       functions: functions.map((func) => ({
  //         functionType: func.type,
  //         table: routeData.table,
  //         filter: func.filter,
  //       })),
  //     },
  return {
    path: route.path,
    method: route.method,
    action: {
      inputs: route.inputs.map((input) => ({
        inputType: input.in,
        label: input.label,
        required: input.required,
        hasStaticValue: input.hasStaticValue,
        type: input.inputType,
      })),
      functions: route.functions.map((func) => ({
        type: func.functionType,
        filter: func.filter,
      })),
    },
  };
};
