import React, { useState } from 'react';
import { Form, Input, Button, notification, Alert } from 'antd';
import { forgotPasswordApi } from '../../services/api.service';
import { Link, useNavigate } from 'react-router-dom';

export default function ForgetPassword() {
  const [form] = Form.useForm();

  const [emailSent, setEmailSent] = useState(false);
  const handleSubmit = (values) => {
    form.resetFields();
    forgotPasswordApi(values)
      .then(({ data }) => {
        console.log('data', data);
        setEmailSent(true);
      })
      .catch((err) => {
        notification.error({
          message: 'Email not match',
          description: err.message,
        });
      });
  };

  return (
    <div className="form-wrapper auth-form pt-5 p-3">
      <Form layout="vertical" form={form} onFinish={handleSubmit}>
        <div className="head mb-3">
          <h3 className="text-white mb-2">Forgot your password?</h3>
          <p className="text-secondary mb-4 fs-6 mb-0">
            Enter your email address and we&lsquo;ll email you a link you can
            use to reset your password.
          </p>
        </div>

        {emailSent && (
          <div className="pt-2 mb-4">
            <Alert
              description="We have sent you an email with a link to reset your password."
              type="success"
              showIcon
            />
          </div>
        )}

        <Form.Item name={'email'} label="Email address">
          <Input placeholder="tony@starkindustries.com" />
        </Form.Item>

        <Button block size="large" htmlType="submit">
          Email Reset Code
        </Button>
      </Form>
      <div className="mt-3 text-center">
        <p className="text-muted">
          <Link
            className="text-secondary text-decoration-none"
            aria-current="page"
            to="/login"
          >
            Or, Try Logging In Again
          </Link>
        </p>
      </div>
    </div>
  );
}
