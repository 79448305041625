import React, { useEffect, useState } from 'react';
import ProjectSection from '../../components/sections/ProjectSection/ProjectSection';
import UIButton from '../../components/ui/UIButton/UIButton';
import {
  TbBrandGithub,
  TbCloudDownload,
  TbDatabaseExport,
  TbLink,
} from 'react-icons/tb';
import ContentSection from '../../components/sections/ContentSection/ContentSection';
import { Alert, Card, Col, Modal, Row, Space } from 'antd';
import {
  createAndPublishRepoApi,
  getGithubOrganizationsApi,
} from '../../services/api.service';
import { useProject } from '../../context/project';

export default function ProjectExport() {
  const { project } = useProject();
  const [githubModal, setGithubModal] = useState(false);
  const [repo, setRepo] = useState(null);
  const [loading, setLoading] = useState(false);
  // useEffect(() => {
  //   getGithubOrganizationsApi().then((data) => {
  //     console.log(data);
  //   });
  // }, []);

  const pubishToRepo = () => {
    setLoading(true);
    // createAndPublishRepoApi(project._id).then((repo) => {
    //   console.log(repo);
    //   setRepo(repo);
    //   setLoading(false);
    // });
  };

  const toggleModal = (action) => () => {
    setGithubModal(action);
  };

  return (
    <ProjectSection title={'Export Project'}>
      <ContentSection>
        <div className="bg-white p-5 shadow-sm">
          <h4 className="fs-3 fw-bold">Export your Project</h4>
          <p className="lead fs-6 text-muted mb-4">
            Export your project as a ZIP file. This will include all your
            project files, including the database schema, routes, and more.
          </p>
          <Row gutter={20}>
            <Col span={8}>
              <Card className='export-card'>
                <h4 className="fs-6">Download your Code as Zip File</h4>
                <p className="text-muted">
                  Download your whole project including the database schema,
                  routes, and more.
                </p>
                <UIButton
                  title={'Download as Zip'}
                  icon={<TbCloudDownload />}
                  theme="primary"
                />
              </Card>
            </Col>
            <Col span={8}>
              <Card className='export-card'>
                <h4 className="fs-6">Push your code to github</h4>
                <p className="text-muted">
                  Download your whole project including the database schema,
                  routes, and more.
                </p>
                <UIButton
                  title={'Push to Github'}
                  icon={<TbBrandGithub />}
                  onClick={toggleModal(true)}
                />
                <p className='mt-3'>Logged in as : samarkundal</p>
              </Card>
            </Col>
          </Row>
        </div>
      </ContentSection>
      <Modal
        open={githubModal}
        title="Publish Repo to Github"
        footer={false}
        onCancel={toggleModal(false)}
      >
        {repo ? (
          <div>
            <p>Repo has been pushed to github.</p>
            <Alert message={repo.name} type="success" />
            <a
              href={repo.svn_url}
              target="_blank"
              className="mt-3 btn btn-link"
            >
              Open in New Tab
            </a>
          </div>
        ) : (
          <div>
            <p>
              This will create a new repository on Github and push your project
              files to the newly created repo.
            </p>
            <p className='text-muted'>
              Estimated Duration: 5 Mins
            </p>
            <UIButton
              title={'Publish to Github'}
              theme="primary"
              icon={<TbDatabaseExport />}
              onClick={pubishToRepo}
              loading={loading}
            />
          </div>
        )}
      </Modal>
    </ProjectSection>
  );
}
