import { Card, Tooltip } from 'antd';
import React from 'react';
import UIButton from '../../ui/UIButton/UIButton';
import './TemplateCard.scss';

export default function TemplateCard({ template, onClick }) {

//   const { modals, showModal} = useModals();

//   const showCreateProjectModal = () => {
//     showModal(MODALS.CREATE_PROJECT_MODAL, { selectedTemplate: template._id });
//   }

  return (
    <Card className="template-card" title={template.title} >
      <p className="template-desc">{template.shortDescription}</p>
      <div className="examples mb-4">
        Examples - {template.example}
      </div>
      {/* <div className="template-examples">
        <Tooltip title="Zomato">
          <div className="template-single-logo-example">
            <img src="https://upload.wikimedia.org/wikipedia/commons/7/75/Zomato_logo.png" alt="" />
          </div>
        </Tooltip>
        <Tooltip title="Open Table">
          <div className="template-single-logo-example">
            <img src="https://play-lh.googleusercontent.com/W8ttTWe4HKbVbPRQMmoo0pkXX3Stp7iR3Veiw6DRfqGR0UVSGpQKKH3s8U5R9IwyKh8=w240-h480-rw" alt="" />
          </div>
        </Tooltip>
        <Tooltip title="Open Table">
          <div className="template-single-logo-example">
            <img src="https://downloadr2.apkmirror.com/wp-content/uploads/2019/06/5cf7beca86b00.png" alt="" />
          </div>
        </Tooltip>
      </div> */}
      <UIButton title={'Use Template'} onClick={onClick} />
    </Card>
  );
}
