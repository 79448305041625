import React from 'react';
import ProjectLayout from '../projects/ProjectLayout';
import { Outlet } from 'react-router-dom';
import ProjectHomeLayout from './ProjectHomeLayout';
import ProjectSection from '../../components/sections/ProjectSection/ProjectSection';
import ContentSection from '../../components/sections/ContentSection/ContentSection';
import {
  Badge,
  Button,
  Card,
  Col,
  Descriptions,
  Row,
  Statistic,
  message,
} from 'antd';
import { useProject } from '../../context/project';
import moment from 'moment';
import { Bar, BarChart, ResponsiveContainer } from 'recharts';
import ProjectQueriesChart from '../../components/projects/ProjectQueriesChart/ProjectQueriesChart';

export default function ProjectSummary() {
  const { project } = useProject();

  const getUrl = () => {
    return project?.url + '/api-docs';
  };

  const copyToken = () => {
    navigator.clipboard.writeText(project?.secretToken);
    message.success('Copied to clipboard');
  };


  return (
    <ProjectSection title={'Project Summary'}>
      <ContentSection>
        {/* <Row gutter={16}>
          <Col span={24}>
            <h6 className="fs-6 mb-3">This Week</h6>
          </Col>
          <Col span={6}>
            <Card>
              <Statistic title="Total Collections" />
            </Card>
          </Col>
          <Col span={6}>
            <Card>
              <Statistic title="Total Collections" />
            </Card>
          </Col>
          <Col span={6}>
            <Card>
              <Statistic title="Total Collections" />
            </Card>
          </Col>
          <Col span={6}>
            <Card>
              <Statistic title="Total Collections" />
            </Card>
          </Col>
        </Row> */}
        <Row>
          <Col span={24}>
            <h6 className="fs-6 mb-3 mt-4">Project Information</h6>
          </Col>
          <Col span={24}>
            <Descriptions
              bordered={true}
              // layout='vertical'
              items={[
                {
                  label: 'Project Name',
                  children: project?.title,
                },
                // {
                //   label: 'Project ID',
                //   children: 'my-project',
                // },
                {
                  label: 'Project Owner',
                  children: project?.user?.name,
                },
                {
                  label: 'Project Status',
                  children: <Badge status="processing" text="Active" />,
                },
                {
                  label: 'Project Created',
                  children: moment(project?.createdAt).format(
                    'MMM Do YY, h:mm a'
                  ),
                },
                {
                  label: 'Last Updated',
                  children: moment(project?.updatedAt).format(
                    'MMM Do YY, h:mm a'
                  ),
                },
                {
                  label: 'Project URL',
                  children: (
                    <a href={getUrl()} target="_blank">
                      {getUrl()}
                    </a>
                  ),
                },
                {
                  label: 'Project Secret',
                  children: (
                    <div className="d-flex align-items-center">
                      <span>
                        {project?.secretToken.slice(0, 10) +
                          ' ********************'}
                      </span>
                      <Button size="small" className="ms-2" onClick={copyToken}>
                        View
                      </Button>
                      <Button
                        size="small"
                        type="text"
                        className="ms-2"
                        onClick={copyToken}
                      >
                        Copy
                      </Button>
                    </div>
                  ),
                },
              ]}
            />
          </Col>
        </Row>
        <Row className="mt-4" gutter={20}>
          <Col span={6}>
            <Card bordered={false}>
              <Statistic title="Total Collections" value={'09'} />
            </Card>
          </Col>
          <Col span={6}>
            <Card bordered={false}>
              <Statistic title="New Collections (this week)" value={'09'} />
            </Card>
          </Col>
          <Col span={6}>
            <Card bordered={false}>
              <Statistic title="Storage Usage" value={'4.24 MB'} />
            </Card>
          </Col>
          <Col span={6}>
            <Card bordered={false}>
              <Statistic title="New Users" value={'00'} />
            </Card>
          </Col>
        </Row>
        <Row className="mt-4" gutter={20}>
          <Col span={12}>
            <ProjectQueriesChart title={"New Requests per day"} />
          </Col>
          <Col span={12}>
            <ProjectQueriesChart title={"New Users per day"} />
          </Col>
        </Row>
      </ContentSection>
    </ProjectSection>
  );
}

// Total Collections, Total Users,
// Flowchart for Queries per day, New Users per day
