import React, { useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { signupApi } from '../../services/api.service';
import {
  saveUserToLocalstorage,
  setAccessToken,
} from '../../services/localstorage';
import { useAuth } from '../../context/authContext';
import { Form, Input, notification } from 'antd';

export default function Signup() {
  const { user, setUser } = useAuth();
  const navigate = useNavigate();

  const handleSubmit = (values) => {
    signupApi(values)
      .then(({ data }) => {
        setAccessToken(data.token);
        setUser(data);
        saveUserToLocalstorage(data);
        window.location.href = '/';
      })
      .catch((err) => {
        notification.error({
          message: 'Unable to Signup!',
          description: err.message,
        });
      });
  };

  return (
    <div className="form-wrapper auth-form">
      <Form layout="vertical" onFinish={handleSubmit}>
        <h3>Create a new Account</h3>
        <hr />
        <Form.Item
          name={'name'}
          label="Name"
          rules={[
            {
              required: true,
              message: 'Please input your name!',
            },
          ]}
        >
          <Input placeholder="Tony Stark" />
        </Form.Item>

        <Form.Item
          name={'email'}
          label="Email"
          rules={[
            {
              required: true,
              message: 'Please input your email!',
            },
          ]}
        >
          <Input placeholder="tony@starkindustries.com" />
        </Form.Item>

        <Form.Item name={'password'} label="Password" rules={[
          {
            required: true,
            message: 'Please input your password!',
          }
        ]}>
          <Input placeholder="*******" type="password" />
        </Form.Item>

        <button type="submit">Create Account</button>
      </Form>
      <p className="mt-4 account-link">
        Already have an account? <Link to="/login">Login</Link>
      </p>
    </div>
  );
}
