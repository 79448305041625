import { Button, Checkbox, Col, Form, Input, Row, Select, Space, message } from 'antd';
import React from 'react';
import { TbCheck, TbCirclePlus, TbMinus, TbTrash } from 'react-icons/tb';
import YAML from 'yaml';
import './CollectionTableForm.scss';
import { useMutation, useQueryClient } from 'react-query';
import { updateTableApi } from '../../../../services/api.service';
import { convertSchemaObjectToSchemaArray } from '../../../../utils';
import UIButton from '../../../ui/UIButton/UIButton';

export default function CollectionTableForm({ collection, onClose }) {

  const queryClient = useQueryClient();
  const mutation = useMutation(updateTableApi, {
    onSuccess: (data) => {
      message.open({
        type: 'success',
        content: 'Collection updated successfully',
        duration: 5,
      });
      queryClient.invalidateQueries('tables');
      onClose();
    },
  });

  const onFinish = (values) => {
    console.log('Received values of form:', values);
    const schema = {};
    values.fields.forEach((field) => {
      schema[field.name] = {
        type: field.type,
        required: field.required || false,
        defaultValue: field.defaultValue,
      };
    });
    console.log('schema', schema);
    mutation.mutate({
      projectId: collection.project,
      id: collection._id,
      body: {
        schemaJson: schema,
      },
    });
  };

  return (
    <Form
      name="dynamic_form_nest_item"
      onFinish={onFinish}
      autoComplete="off"
      className="collection-table-form"
      layout="vertical"
      initialValues={{
        fields: convertSchemaObjectToSchemaArray(collection.schemaJson),
      }}
    >
      <Form.List name="fields">
        {(fields, { add, remove }) => (
          <>
            {fields.map(({ key, name, ...restField }) => (
              <div className="single-param-row" key={key}>
                { console.log('restField' )}
                <Row key={key} gutter={10}>
                  <Col span={7}>
                    <Form.Item
                      {...restField}
                      name={[name, 'name']}
                      rules={[
                        {
                          required: true,
                          message: 'Missing Attribute name',
                        },
                      ]}
                      className="mb-0"
                    >
                      <Input placeholder="Attribute name" />
                    </Form.Item>
                  </Col>
                  <Col span={5}>
                    <Form.Item
                      {...restField}
                      name={[name, 'type']}
                      rules={[
                        {
                          required: true,
                          message: 'Missing Attribute Type',
                        },
                      ]}
                      className="mb-0"
                    >
                      <Select placeholder="Select Type">
                        <Select.Option value="String">String</Select.Option>
                        <Select.Option value="Number">Number</Select.Option>
                        <Select.Option value="Boolean">Boolean</Select.Option>
                        <Select.Option value="Date">Date</Select.Option>
                        <Select.Option value="ObjectId">ObjectId</Select.Option>
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col span={7}>
                    <Form.Item
                      {...restField}
                      name={[name, 'defaultValue']}
                      className="mb-0"
                    >
                      <Input placeholder="Default Value" />
                    </Form.Item>
                  </Col>
                  <Col span={4}>
                    <Form.Item
                      {...restField}
                      name={[name, 'required']}
                      className="mb-0"
                      valuePropName="checked"
                    >
                      <Checkbox value={true}>Required</Checkbox>
                    </Form.Item>
                  </Col>
                  <Col span={1}>
                    <Button type="text" className="px-0 py-0">
                      <TbTrash />
                    </Button>
                  </Col>
                </Row>
              </div>
            ))}
            <Form.Item>
              <Button
                type="dashed"
                onClick={() => add()}
                block
                icon={<TbCirclePlus />}
              >
                Add field
              </Button>
              
            </Form.Item>
          </>
        )}
      </Form.List>
      <Form.Item>
        <UIButton
          title={'Update Schema'}
          icon={<TbCheck />}
          theme="primary"
          type='submit'
        />
      </Form.Item>
    </Form>
  );
}
